import { axiosInstanceWoToken } from "../../AxiosInstance";
import { loginSuccess, logout } from "./authSlice";

export const login = (credentials) => async (dispatch) => {
  try {
    const response = await axiosInstanceWoToken.post("login/", credentials);
    const { token, user } = response.data;

    localStorage.setItem("access_token", token.access);
    localStorage.setItem("refresh_token", token.refresh);

    dispatch(loginSuccess({ token, user }));
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const performLogout = () => (dispatch) => {
  localStorage.removeItem("token");
  dispatch(logout());
};
