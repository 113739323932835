import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { MultiSelectInput } from "../../components/input/inputs";
import main from "../../styles/main.module.css";
import esES from 'rsuite/locales/es_ES';
import { CustomProvider, DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import axiosInstance from "../../AxiosInstance";
import MyPaginate from "../../components/react-pagination";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function EventList() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    const savedSidebarState = localStorage.getItem('isSidebarCollapsed');
    return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [events, setEvents] = useState([]);
  const [zones, setZones] = useState([]);
  const [gatesystem, setGatesystem] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    axiosInstance.get('zona/').then((response) => {
      setZones(response.data);
    });

    axiosInstance.get('gatesystem/').then((response) => {
      setGatesystem(response.data.results);
    });

    fetchEvents();
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [currentPage, filters]);

  const fetchEvents = () => {
    const params = Object.keys(filters)
      .map((key) => Array.isArray(filters[key]) ? `${key}=${filters[key].join(',')}` : `${key}=${filters[key]}`)
      .join('&');

    axiosInstance.get(`events/?page=${currentPage}&${params}`).then((response) => {
      setEvents(response.data.results);
      setTotalPages(response.data.total_pages);
    });
  };

  const handleSidebarToggle = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  const handleSetFormZone = (values) => {
    setFilters((prev) => ({
      ...prev,
      zona: values,
    }));
  };

  const handleSetFormGate = (values) => {
    setFilters((prev) => ({
      ...prev,
      gatesystem: values,
    }));
  };

  const configuration = {
    sunday: 'D',
    monday: 'L',
    tuesday: 'M',
    wednesday: 'Mi',
    thursday: 'J',
    friday: 'V',
    saturday: 'S',
    ok: 'Buscar',
    formattedMonthPattern: 'MMMM yyyy',
  };

  const handleDateRange = (values) => {
    if (values.length < 2) return;
    setFilters((prev) => ({
      ...prev,
      start_date: dayjs(values[0]).format("YYYY-MM-DDTHH:mm:ss"),
      end_date: dayjs(values[1]).format("YYYY-MM-DDTHH:mm:ss"),
    }));
  };

  return (
    <div>
      <Header current={3} onSidebarToggle={handleSidebarToggle} />
      <div className={`${main.mainContent} ${isSidebarCollapsed ? main.expanded : main.collapsed}`}>
        <div style={{ flexShrink: '0', flexBasis: '100px', textAlign: 'left' }} className={`${main.listTitle} mt-3`}>
          Eventos
        </div>

        <div className={main.filterContainer}>
          <div className={`${main.filterItem}`}>
            <img src="/filter_icon.svg" alt="filtro" width="19px" />
            <div className={main.filterLabel}>Filtro</div>
          </div>
          <div style={{ width: '200px' }}>
            <MultiSelectInput label={'Todas las zonas'} options={zones?.map((x) => { return { id: x.id, name: x.nombre_zona } })} setForm={handleSetFormZone} />
          </div>
          <div style={{ width: '200px' }}>
            <CustomProvider locale={esES}>
              <DateRangePicker
                locale={configuration}
                showHeader={false}
                showOneCalendar
                ranges={[]}
                format="dd/MM/yyyy hh:mm"
                showMeridian={true}
                caretAs={IconCalendar}
                onChange={handleDateRange}
                placeholder="Seleccione un horario"
              />
            </CustomProvider>
          </div>
          <div style={{ width: '200px' }}>
            <MultiSelectInput label={'Todas las barreras'} options={gatesystem?.map((x) => { return { id: x.id, name: x.nombre } })} setForm={handleSetFormGate} />
          </div>
        </div>
        <table className={main.listTable}>
          <thead>
            <tr>
              <th>Evento</th>
              <th>Sistema de Barrera</th>
              <th>Zona</th>
              <th>Fecha y Hora</th>
            </tr>
          </thead>
          <tbody>
            {events.map((item) => (
              <tr key={item.id}>
                <td>{item.event}</td>
                <td>{item.gate_nombre}</td>
                <td>{item.zona_nombre}</td>
                <td>{dayjs(item.datetime).format("DD/MM/YYYY HH:mm A")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <MyPaginate
          className="mt-3"
          breakLabel="..."
          nextLabel="Siguiente"
          onPageChange={(page) => setCurrentPage(page.selected + 1)}
          pageRangeDisplayed={3}
          pageCount={totalPages}
          previousLabel="Anterior"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}

const IconCalendar = () => {
  return (<FontAwesomeIcon icon={faChevronDown} />);
}
