// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__VAYMg {
    -webkit-user-select: none;
            user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 315px 0 8px #ccc;
    background-color: white;
    z-index: 4;
    padding: 10px 0;
}

.header_userMenu__LjRW3 {
    display: flex;
    align-items: center;
}

.header_userIcon__COF0a {
    background-color: #FFF;
    cursor: pointer;
}

.header_chevronIcon__gOxHT {
    background-color: #E7EAF0;
    cursor: pointer;
}

.header_dropdown__ZDj6p {
    position: absolute;
    top: 60px;
    right: 10px;
    z-index: 1;
    background-color: #fff;
    border: 3px solid white;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    min-width: 160px;
}

.header_dropdownItem__FO2i0 {
    font-weight: 500;
    text-align: left;
    display: block;
    padding: 8px 15px;
    color: #162949;
    cursor: pointer;
    text-decoration: none;
}

.header_dropdownItem__FO2i0:hover {
    background-color: #F6F7FC;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/header.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,4BAA4B;IAC5B,uBAAuB;IACvB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,UAAU;IACV,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,yCAAyC;IACzC,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".header {\n    user-select: none;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    box-shadow: 315px 0 8px #ccc;\n    background-color: white;\n    z-index: 4;\n    padding: 10px 0;\n}\n\n.userMenu {\n    display: flex;\n    align-items: center;\n}\n\n.userIcon {\n    background-color: #FFF;\n    cursor: pointer;\n}\n\n.chevronIcon {\n    background-color: #E7EAF0;\n    cursor: pointer;\n}\n\n.dropdown {\n    position: absolute;\n    top: 60px;\n    right: 10px;\n    z-index: 1;\n    background-color: #fff;\n    border: 3px solid white;\n    border-radius: 5px;\n    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);\n    min-width: 160px;\n}\n\n.dropdownItem {\n    font-weight: 500;\n    text-align: left;\n    display: block;\n    padding: 8px 15px;\n    color: #162949;\n    cursor: pointer;\n    text-decoration: none;\n}\n\n.dropdownItem:hover {\n    background-color: #F6F7FC;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__VAYMg`,
	"userMenu": `header_userMenu__LjRW3`,
	"userIcon": `header_userIcon__COF0a`,
	"chevronIcon": `header_chevronIcon__gOxHT`,
	"dropdown": `header_dropdown__ZDj6p`,
	"dropdownItem": `header_dropdownItem__FO2i0`
};
export default ___CSS_LOADER_EXPORT___;
