import axios from "axios";

// const isDevelopment = process.env.NODE_ENV === "development";
const useLocal = process.env.REACT_APP_USE_LOCAL === "true";

export const baseUrl = useLocal
  ? "http://localhost:8000/api/"
  : "https://tranqueras-admin.stracontech.com/api/";

export const baseUrlSockets = useLocal
  ? "ws://localhost:8000/ws/central/"
  : "wss://tranqueras-admin.stracontech.com/ws/central/";

export const axiosInstanceWoToken = axios.create({
  baseURL: baseUrl,
});

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem("refresh_token");

        if (!refreshToken) {
          throw new Error("No refresh token available");
        }

        const response = await axiosInstanceWoToken.post("token/refresh/", {
          refresh: refreshToken,
        });

        console.log("refresh response", response.data)

        const newAccessToken = response.data.access;
        const newRefreshToken = response.data.refresh;
        localStorage.setItem("access_token", newAccessToken);
        localStorage.setItem("refresh_token", newRefreshToken);

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Error refreshing token:", refreshError);
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
