import React, { useEffect, useState } from "react";

import Header from "../../components/header";
import { MenuButton, TextInput } from "../../components/input/inputs";
import Modal from "../../components/modal/modal";
import styles from "../../styles/authorizations.module.css";
import userStyles from "../users/styles/list.module.css";
import main from "../../styles/main.module.css";
import axiosInstance from "../../AxiosInstance";
import MyPaginate from "../../components/react-pagination";
import AuthorizationBadge from "../../components/authorization_badge";

const INITIAL_FORM = {
  numero_mina: "",
  matricula: "",
  modelo: "",
  autorizado: false,
};

export default function PersonList() {
  const [paginatedData, setPaginatedData] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [form, setForm] = useState(INITIAL_FORM);
  const [file, setFile] = useState(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    const savedSidebarState = localStorage.getItem("isSidebarCollapsed");
    return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
  });
  const [itemMenuOpen, setItemMenuOpen] = useState({
    idUser: null,
  });
  const [errorMessages, setErrorMessages] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [addOrEdit, setAddOrEdit] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userList = await getPaginatedData(currentPage, searchTerm);
        setPaginatedData(userList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, searchTerm]);

  const getPaginatedData = (page = 1, search = "") =>
    axiosInstance
      .get(`vehiculos/?page=${page}&search=${search}`)
      .then((response) => {
        setTotalPages(response.data.total_pages);
        setCurrentPage(response.data.current_page);
        return response.data;
      });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      alert("Por favor, selecciona un archivo Excel (.xlsx).");
    }
  };

  const handleUploadFile = async () => {
    if (loading) return;
    if (!file) {
      alert("Por favor, selecciona un archivo para subir.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      await axiosInstance.post("vehiculos/import-export-vehiculos/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setFile(null);
      setShowUploadModal(false);
      const paginatedData = await getPaginatedData();
      setPaginatedData(paginatedData);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error al subir el archivo. Por favor, intenta de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadFile = async () => {
    try {
      let url = "vehiculos/import-export-vehiculos/";
      if (selectedIds.length > 0) {
        const idsQuery = selectedIds.join(",");
        url += `?ids=${idsQuery}`;
      }

      const response = await axiosInstance.get(url, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Vehículos.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Error al descargar el archivo. Por favor, intenta de nuevo.");
    }
  };

  const handleSidebarToggle = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  const validateForm = () => {
    let errors = {};
    if (!form.numero_mina)
      errors.id = "El documento de identidad es obligatorio.";
    if (!form.matricula) errors.first_name = "El nombre es obligatorio.";
    if (!form.modelo) errors.last_name = "El apellido es obligatorio.";

    return errors;
  };

  const handleForm = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      setGeneralError("Por favor, complete todos los campos obligatorios.");
      return;
    }

    const url = addOrEdit ? `vehiculos/${form.numero_mina}/` : "vehiculos/";
    const method = addOrEdit ? "patch" : "post";

    try {
      await axiosInstance[method](url, form);
      const paginatedData = await getPaginatedData();
      setPaginatedData(paginatedData);
      setShowCreateModal(false);
      resetForm();
      setErrorMessages({});
      setGeneralError("");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessages(error.response.data);
      } else {
        console.error("Error handling form submission:", error);
      }
    }
  };

  const handleCancelCreate = () => {
    resetForm();
  };

  const handleItemMenu = (id) => {
    setItemMenuOpen({
      idUser: itemMenuOpen.idUser === id ? null : id,
    });
  };

  const handleEditUser = (vehiculo) => {
    setAddOrEdit(1);
    setShowCreateModal(true);
    setForm({
      numero_mina: vehiculo.numero_mina,
      matricula: vehiculo.matricula,
      modelo: vehiculo.modelo,
      autorizado: vehiculo.autorizado,
      empresa_responsable: vehiculo.empresa_responsable,
      vigencia: vehiculo.vigencia,
    });
  };

  const handleDeleteUser = (vehiculo) => {
    setShowDeleteModal(true);
    setForm({
      numero_mina: vehiculo.numero_mina,
      matricula: vehiculo.matricula,
      modelo: vehiculo.modelo,
      autorizado: vehiculo.autorizado,
      empresa_responsable: vehiculo.empresa_responsable,
      vigencia: vehiculo.vigencia,
    });
  };

  const handleConfirmDelete = async () => {
    const { numero_mina } = form;
    try {
      await axiosInstance.delete(`vehiculos/${numero_mina}/`);
      const userList = await getPaginatedData();
      setPaginatedData(userList);
      setShowDeleteModal(false);
      resetForm();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const resetForm = () => {
    setForm(INITIAL_FORM);
    setErrorMessages({});
    setGeneralError("");
  };

  const handleSelectId = (numero_mina) => {
    setSelectedIds((prev) => {
      if (prev.includes(numero_mina)) {
        return prev.filter((selectedId) => selectedId !== numero_mina);
      } else {
        return [...prev, numero_mina];
      }
    });
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedIds([]);
    } else {
      const allIds = paginatedData.results.map(
        (vehiculo) => vehiculo.numero_mina,
      );
      setSelectedIds(allIds);
    }
    setAllSelected(!allSelected);
  };

  return (
    <div
      className={`${main.mainContent} ${isSidebarCollapsed ? main.expanded : main.collapsed}`}
    >
      <Header current={6} onSidebarToggle={handleSidebarToggle} />

      <Modal
        show={showUploadModal}
        setter={setShowUploadModal}
        name="Subir archivo Excel"
        buttonName="Subir"
        onClickCancel={() => setShowUploadModal(false)}
        onClick={handleUploadFile}
      >
        <div className="container-fluid">
          <div className="form-group">
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div className="form-group">
            <p>
              Puedes descargar la plantilla del archivo{" "}
              <a href="/TranquerasVehiculosPlantilla.xlsx" download>
                aquí
              </a>
              .
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        show={showCreateModal}
        setter={setShowCreateModal}
        name={`${addOrEdit ? "Editar" : "Agregar"} Usuario`}
        buttonName={addOrEdit ? "Guardar" : "Agregar"}
        onClickCancel={handleCancelCreate}
        onClick={handleForm}
      >
        <div className="container-fluid">
          {generalError && (
            <div className="alert alert-danger">{generalError}</div>
          )}
          <div className="form-group">
            <TextInput
              name={"Numero Mina"}
              form={form}
              setForm={setForm}
              internalName={"numero_mina"}
              errorMessage={errorMessages.documento_identidad}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Matrícula"}
              form={form}
              setForm={setForm}
              internalName={"matricula"}
              errorMessage={errorMessages.first_name}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Modelo"}
              form={form}
              setForm={setForm}
              internalName={"modelo"}
              errorMessage={errorMessages.last_name}
            />
          </div>
          <div className="form-group">
            <div className="mb-1 d-flex" style={{ gap: "46px" }}>
              <div>Autorizado</div>
              <input
                type="checkbox"
                checked={form.autorizado}
                onChange={(e) =>
                  setForm({ ...form, autorizado: e.target.checked })
                }
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={showDeleteModal}
        setter={setShowDeleteModal}
        name={"Eliminar Usuario"}
        buttonName={"Eliminar"}
        onClickCancel={handleCancelCreate}
        onClick={handleConfirmDelete}
      >
        <div>
          <div className={"d-flex flex-row align-items-center w-100"}>
            {`¿Está seguro que desea eliminar al usuario ${form.nombre} ${form.apellido}?`}
          </div>
        </div>
      </Modal>

      <div className={"bg-light vh-100 mt-3"}>
        <div className={`${styles.createContainer}`}>
          <div
            className={"d-flex align-items-center"}
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <div className={`${styles.listTitle}`}>Lista de Vehículos</div>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <button
                  className={`btn ${main.mainButton}`}
                  onClick={() => {
                    setShowCreateModal(true);
                    setAddOrEdit(0);
                  }}
                >
                  Agregar
                </button>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className={`btn ${main.mainButton}`}
                  onClick={handleDownloadFile}
                >
                  Descargar
                </button>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className={`btn ${main.mainButton}`}
                  onClick={() => setShowUploadModal(true)}
                >
                  Subir
                </button>
              </div>
              <div className="d-flex align-items-center">
                <input
                  className="inputBorderDefault form-control rounded-2"
                  placeholder="Buscar"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
        </div>

        <table className={`${styles.listTable} mt-3`}>
          <thead>
            <tr>
              <th className={styles.tableItemHeader}>
                <input
                  type="checkbox"
                  checked={allSelected}
                  onChange={handleSelectAll}
                />
              </th>
              <th className={`${styles.tableItemHeader}`}>Numero Mina</th>
              <th className={`${styles.tableItemHeader}`}>Matrícula</th>
              <th className={`${styles.tableItemHeader}`}>Modelo</th>
              <th className={`${styles.tableItemHeader}`}>Autorizado</th>
              <th className={`${styles.tableItemHeader}`}>
                Empresa Responsable
              </th>
              <th className={`${styles.tableItemHeader}`}>Vigencia</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {paginatedData &&
              paginatedData.results.map((vehiculo) => (
                <tr key={vehiculo.numero_mina}>
                  <td className={styles.tableItem}>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(vehiculo.numero_mina)}
                      onChange={() => handleSelectId(vehiculo.numero_mina)}
                    />
                  </td>
                  <td className={`${styles.tableItem}`}>
                    {vehiculo.numero_mina}
                  </td>
                  <td className={`${styles.tableItem}`}>
                    {vehiculo.matricula}
                  </td>
                  <td className={`${styles.tableItem}`}>{vehiculo.modelo}</td>
                  <td className={`${styles.tableItem}`}>
                    <AuthorizationBadge authorized={vehiculo.autorizado} />
                  </td>
                  <td className={`${styles.tableItem}`}>
                    {vehiculo.empresa_responsable
                      ? vehiculo.empresa_responsable
                      : "-"}
                  </td>
                  <td className={`${styles.tableItem}`}>
                    {new Intl.DateTimeFormat("es-ES", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }).format(new Date(vehiculo.vigencia))}
                  </td>

                  <td>
                    <MenuButton
                      onClick={() => handleItemMenu(vehiculo.numero_mina)}
                    >
                      {itemMenuOpen.idUser === vehiculo.numero_mina && (
                        <div className={`${userStyles.dropdown}`}>
                          <div
                            onClick={() => handleEditUser(vehiculo)}
                            className={`${userStyles.dropdownItem}`}
                          >
                            Editar
                          </div>
                          <div
                            onClick={() => handleDeleteUser(vehiculo)}
                            className={`${userStyles.dropdownItem}`}
                          >
                            Eliminar
                          </div>
                        </div>
                      )}
                    </MenuButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <MyPaginate
          breakLabel="..."
          nextLabel="Siguiente"
          onPageChange={(page) => setCurrentPage(page.selected + 1)}
          pageRangeDisplayed={3}
          pageCount={totalPages}
          previousLabel="Anterior"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}
