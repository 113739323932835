// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authorization_modals_container__fbiHR {
    border: #FFF 5px solid;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50rem;
    height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.authorization_modals_icon__Z9XBb {
    border: #FFF 5px solid;
    border-radius: 40px;
    padding: 5px 10px 5px 10px;
}

.authorization_modals_icon_gross__CjLiw {
    border: #FFF 5px solid;
    border-radius: 40px;
    padding: 5px 26px 5px 26px;
}

.authorization_modals_background_green__EkpV- {
    background: linear-gradient(to bottom left, #53C0D6 0%, #0AB1A5 70%);
    height: 100%;
}

.authorization_modals_background_red__pNaQH {
    background: linear-gradient(to bottom left, #FC909A 0%, #E01B4A 70%);
    height: 100%;
}

.authorization_modals_text__HopUz {
    color: #FFF;
    font-size: xxx-large;
    font-weight: 600;
 }

.authorization_modals_text_2__XYl75 {
    color: #FFF;
    font-size: x-large;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/styles/authorization_modals.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,oEAAoE;IACpE,YAAY;AAChB;;AAEA;IACI,oEAAoE;IACpE,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,gBAAgB;CACnB;;AAED;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".container {\n    border: #FFF 5px solid;\n    border-radius: 10px;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 50rem;\n    height: 30rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.icon {\n    border: #FFF 5px solid;\n    border-radius: 40px;\n    padding: 5px 10px 5px 10px;\n}\n\n.icon_gross {\n    border: #FFF 5px solid;\n    border-radius: 40px;\n    padding: 5px 26px 5px 26px;\n}\n\n.background_green {\n    background: linear-gradient(to bottom left, #53C0D6 0%, #0AB1A5 70%);\n    height: 100%;\n}\n\n.background_red {\n    background: linear-gradient(to bottom left, #FC909A 0%, #E01B4A 70%);\n    height: 100%;\n}\n\n.text {\n    color: #FFF;\n    font-size: xxx-large;\n    font-weight: 600;\n }\n\n.text_2 {\n    color: #FFF;\n    font-size: x-large;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `authorization_modals_container__fbiHR`,
	"icon": `authorization_modals_icon__Z9XBb`,
	"icon_gross": `authorization_modals_icon_gross__CjLiw`,
	"background_green": `authorization_modals_background_green__EkpV-`,
	"background_red": `authorization_modals_background_red__pNaQH`,
	"text": `authorization_modals_text__HopUz`,
	"text_2": `authorization_modals_text_2__XYl75`
};
export default ___CSS_LOADER_EXPORT___;
