// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_container__mx-db {\n\n}\n\n.modal_header__ZrtET {\n    padding: 16px;\n}\n\n.modal_header__ZrtET div:first-child {\n    font-weight: 600;\n    color: #334155;\n    font-size: 20px;\n}\n\n.modal_header__ZrtET div:last-child {\n    font-weight: 400;\n}\n\n.modal_body__MX-ja {\n    font-weight: 400;\n    padding-right: 16px;\n    padding-left: 16px;\n}\n\n.modal_footer__O9LYa {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    display: flex;\n    justify-content: flex-end;\n    padding-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/modal/modal.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".container {\n\n}\n\n.header {\n    padding: 16px;\n}\n\n.header div:first-child {\n    font-weight: 600;\n    color: #334155;\n    font-size: 20px;\n}\n\n.header div:last-child {\n    font-weight: 400;\n}\n\n.body {\n    font-weight: 400;\n    padding-right: 16px;\n    padding-left: 16px;\n}\n\n.footer {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    display: flex;\n    justify-content: flex-end;\n    padding-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "modal_container__mx-db",
	"header": "modal_header__ZrtET",
	"body": "modal_body__MX-ja",
	"footer": "modal_footer__O9LYa"
};
export default ___CSS_LOADER_EXPORT___;
