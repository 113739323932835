// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".badge_badge__kG\\+\\+F {\n  padding: 0.5em 1em;\n  border-radius: 12px;\n  color: white;\n  font-weight: bold;\n  text-align: center;\n}\n\n.badge_badgeTrue__N-jGF {\n  background-color: green;\n}\n\n.badge_badgeFalse__Soj3h {\n  background-color: red;\n}\n\n", "",{"version":3,"sources":["webpack://./src/screens/vehicles/styles/badge.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".badge {\n  padding: 0.5em 1em;\n  border-radius: 12px;\n  color: white;\n  font-weight: bold;\n  text-align: center;\n}\n\n.badgeTrue {\n  background-color: green;\n}\n\n.badgeFalse {\n  background-color: red;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "badge_badge__kG++F",
	"badgeTrue": "badge_badgeTrue__N-jGF",
	"badgeFalse": "badge_badgeFalse__Soj3h"
};
export default ___CSS_LOADER_EXPORT___;
