import styles from "./styles/authorization_badge.module.css";

const AuthorizationBadge = ({ authorized }) => (
  <span
    className={`${styles.badge} ${authorized ? styles.badgeTrue : styles.badgeFalse}`}
  >
    {authorized ? "Autorizado" : "No autorizado"}
  </span>
);

export default AuthorizationBadge;
