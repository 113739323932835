import React, { useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthorizationCreate from "./screens/authorization/create";
import GalleryList from "./screens/gallery/list";
import GateList from "./screens/gates/list";
import GateProgrammingList from "./screens/gates/programming/list";
import HistoryList from "./screens/history/list";
import EventList from "./screens/events/list";
import Home from "./screens/home/home";
import LoginScreen from "./screens/login";
import PeopleList from "./screens/people/list";
import CreateSchedule from "./screens/schedule/create";
import ScheduleList from "./screens/schedule/list";
import AuthorizedModal from "./screens/states/authorized";
import ErrorModal from "./screens/states/error";
import LoadingModal from "./screens/states/loading";
import UnauthorizedModal from "./screens/states/unauthorized";
import WelcomeModal from "./screens/states/welcome";
import CreateUser from "./screens/users/create";
import UserList from "./screens/users/list";
import VehicleList from "./screens/vehicles/list";
import PersonList from "./screens/persons/list";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrlSockets } from "./AxiosInstance";
import {
  selectIsAuthenticated,
  selectUser,
} from "./features/auth/authSelectors";
import { ROLES } from "./constants/roles";

function LoginRedirect() {
  useEffect(() => {
    window.location.replace("/login");
  }, []);
  return <div></div>;
}

export default function RouteContainer() {
  const user = useSelector(selectUser);
  const authenticated = useSelector(selectIsAuthenticated);
  const socket = useRef(null);
  useEffect(() => {
    const toastConfig = {
      position: "top-right",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    };
    const toastConfigInfo = {
      ...toastConfig,
      className: "toast-info",
    };

    if (authenticated) {
      socket.current = new WebSocket(baseUrlSockets);

      // const newSocket = new WebSocket(baseUrlSockets);
      socket.current.onopen = function () {
        console.log("conexion establecida");
      };
      socket.current.onerror = function () {
        console.log("error en conexion. Intentando reconectar");
        setTimeout(() => {
          socket.current = new WebSocket(baseUrlSockets);
        }, 1000);
      };
      socket.current.onmessage = (event) => {
        const receivedMessage = JSON.parse(event.data);
        if (receivedMessage.type === "eventlistener_created") {
          console.log("Notificacion encontrada");
          const type = receivedMessage.data.event_type;
          // console.log(type)
          if (type === 0) {
            toast.info(receivedMessage.data.event, toastConfigInfo);
          } else {
            toast.error(receivedMessage.data.event, toastConfig);
          }
        }
      };

      socket.current.onclose = function () {
        console.log("La conexion ha sido cerrada");
      };
    } else {
      console.log("cerrando conexion");
      console.log(socket.current);
      if (socket.current !== null) {
        socket.current.close();
      }
    }

    return () => {
      if (socket.current !== null) {
        socket.current.close();
      }
    };
  }, [authenticated]);

  useEffect(() => {
    console.log("authenticated", authenticated);
  });

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<LoginScreen />}>
          Login
        </Route>
        {authenticated && (
          <>
            <Route path="/" element={<Home />}>
              Home
            </Route>
            <Route path="/people/list" element={<PeopleList />}>
              PeopleList
            </Route>
            {user &&
              (user.is_superuser || user.zona.nombre_zona !== ROLES.UJINA) && (
                <>
                  <Route path="/persons/list" element={<PersonList />}>
                    VehicleList
                  </Route>
                  <Route path="/vehicle/list" element={<VehicleList />}>
                    VehicleList
                  </Route>
                </>
              )}
            <Route path="/gates/list" element={<GateList />}>
              GateList
            </Route>
            <Route
              path="/gate_programming/list"
              element={<GateProgrammingList />}
            >
              GateProgrammingList
            </Route>
            <Route path="/gallery/list" element={<GalleryList />}>
              GalleryList
            </Route>
            <Route path="/users/list" element={<UserList />}>
              Lista de usuarios
            </Route>
            <Route path="/users/create" element={<CreateUser />}>
              Crear usuario
            </Route>
            <Route path="/history/list" element={<HistoryList />}>
              HistoryList
            </Route>
            <Route path="/events/list" element={<EventList />}>
              EventsList
            </Route>
            <Route
              path="/authorization/create"
              element={<AuthorizationCreate />}
            >
              Autorizaciones
            </Route>
            <Route path="/schedule/list" element={<ScheduleList />}>
              Tasks
            </Route>
            <Route path="/schedule/create" element={<CreateSchedule />}>
              Tasks
            </Route>
            <Route path="/modal/welcome" element={<WelcomeModal />}>
              Welcome
            </Route>
            <Route path="/modal/authorized" element={<AuthorizedModal />}>
              Authorized
            </Route>
            <Route path="/modal/unauthorized" element={<UnauthorizedModal />}>
              Unauthorized
            </Route>
            <Route path="/modal/error" element={<ErrorModal />}>
              Error
            </Route>
            <Route path="/modal/loading" element={<LoadingModal />}>
              Authorized
            </Route>
          </>
        )}
        <Route path="*" element={<LoginRedirect />} />
      </Routes>
    </>
  );
}
