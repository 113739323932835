// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".authorization_badge_badge__nkp19 {\n  padding: 0.5em 1em;\n  border-radius: 12px;\n  color: white;\n  font-weight: bold;\n  text-align: center;\n  width: 140px;\n  display: inline-block;\n}\n\n.authorization_badge_badgeTrue__Z8MuL {\n  background-color: green;\n}\n\n.authorization_badge_badgeFalse__LyI2C {\n  background-color: red;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/styles/authorization_badge.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".badge {\n  padding: 0.5em 1em;\n  border-radius: 12px;\n  color: white;\n  font-weight: bold;\n  text-align: center;\n  width: 140px;\n  display: inline-block;\n}\n\n.badgeTrue {\n  background-color: green;\n}\n\n.badgeFalse {\n  background-color: red;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "authorization_badge_badge__nkp19",
	"badgeTrue": "authorization_badge_badgeTrue__Z8MuL",
	"badgeFalse": "authorization_badge_badgeFalse__LyI2C"
};
export default ___CSS_LOADER_EXPORT___;
