// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  height: 100vh;
  background-color: #F6F7FC !important;
}

html,
body,
#root {
  height: 100vh;
  background-color: #F6F7FC !important;
}

/* RS RANGE DATE PICKER */
.rs-picker,
.rs-input,
.rs-input-group {
  height: 100%;

}

.rs-input-group.rs-input-group-inside {
  border: 1px solid #ccc;
}

div.rs-input-group.rs-input-group-inside:hover {
  border: 1px solid #4A4DB9;
}

rs-calendar-header-title.rs-btn-subtle {
  font-weight: 700;
  text-transform: capitalize;
}

/* GALLERY */
.sidebar-filter {

  background-color: #fff;
  width: 250px;
  height: 850px;
  box-shadow: 0 0 4px #ccc;
  border-radius: 6px;

}

.galleryContainer {
  min-height: 100vh;
}

@media screen and (max-width:1024px) {
  .sidebar-filter {
    min-height: 60vh;
  }

  .galleryContainer {
    min-height: 60vh;
  }
}

/* Pop - pup */
.toast-info {
  background: orange !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,oCAAoC;AACtC;;AAEA;;;EAGE,aAAa;EACb,oCAAoC;AACtC;;AAEA,yBAAyB;AACzB;;;EAGE,YAAY;;AAEd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA,YAAY;AACZ;;EAEE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,wBAAwB;EACxB,kBAAkB;;AAEpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;AACF;;AAEA,cAAc;AACd;EACE,6BAA6B;AAC/B","sourcesContent":[".App {\n  text-align: center;\n  height: 100vh;\n  background-color: #F6F7FC !important;\n}\n\nhtml,\nbody,\n#root {\n  height: 100vh;\n  background-color: #F6F7FC !important;\n}\n\n/* RS RANGE DATE PICKER */\n.rs-picker,\n.rs-input,\n.rs-input-group {\n  height: 100%;\n\n}\n\n.rs-input-group.rs-input-group-inside {\n  border: 1px solid #ccc;\n}\n\ndiv.rs-input-group.rs-input-group-inside:hover {\n  border: 1px solid #4A4DB9;\n}\n\nrs-calendar-header-title.rs-btn-subtle {\n  font-weight: 700;\n  text-transform: capitalize;\n}\n\n/* GALLERY */\n.sidebar-filter {\n\n  background-color: #fff;\n  width: 250px;\n  height: 850px;\n  box-shadow: 0 0 4px #ccc;\n  border-radius: 6px;\n\n}\n\n.galleryContainer {\n  min-height: 100vh;\n}\n\n@media screen and (max-width:1024px) {\n  .sidebar-filter {\n    min-height: 60vh;\n  }\n\n  .galleryContainer {\n    min-height: 60vh;\n  }\n}\n\n/* Pop - pup */\n.toast-info {\n  background: orange !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
