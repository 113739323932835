import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import esES from "rsuite/locales/es_ES";
import { useSelector } from "react-redux";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CustomProvider, DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import axios from "../../AxiosInstance";
import ModalComponent from "../../components/modal/modalcomponent";
import { selectUser } from "../../features/auth/authSelectors";
import Header from "../../components/header";
import { MultiSelectInput } from "../../components/input/inputs";
import styles from "../../styles/authorizations.module.css";
import main from "../../styles/main.module.css";
import MyPaginate from "../../components/react-pagination";
import { ROLES } from "../../constants/roles";
import AuthorizationBadge from "../../components/authorization_badge";

export default function HistoryList() {
  const user = useSelector(selectUser);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [zones, setZones] = useState([]);
  const [gatesystem, setGatesystem] = useState([]);
  const [history, setHistory] = useState([]);
  const [filters, setFilters] = useState({});
  const [showPhoto, setShowPhoto] = useState(false);
  const [photoDialog, setPhotoDialog] = useState({ url: "" });
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    const savedSidebarState = localStorage.getItem("isSidebarCollapsed");
    return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
  });
  const [totalVehicles, setTotalVehicles] = useState(0);

  useEffect(() => {
    axios.get("zona/").then((response) => {
      setZones(response.data);
    });

    axios.get("gatesystem/").then((response) => {
      setGatesystem(response.data.results);
    });

    fetchHistory();
  }, []);

  useEffect(() => {
    fetchHistory();
  }, [filters, currentPage]);

  const fetchHistory = () => {
    const params = Object.keys(filters)
      .map((key) =>
        Array.isArray(filters[key])
          ? `${key}=${filters[key].join(",")}`
          : `${key}=${filters[key]}`,
      )
      .join("&");

    axios.get(`history/?page=${currentPage}&${params}`).then((response) => {
      setHistory(response.data.results);
      setTotalPages(response.data.total_pages);
      setTotalVehicles(response.data.count);
    });
  };

  const handleSetFormZone = (values) => {
    setFilters((prev) => ({
      ...prev,
      zona: values,
    }));
  };

  const handleSetFormGate = (values) => {
    setFilters((prev) => ({
      ...prev,
      gatesystem: values,
    }));
  };

  const handleDateRange = (values) => {
    if (values.length < 2) return;
    setFilters((prev) => ({
      ...prev,
      start_date: dayjs(values[0]).format("YYYY-MM-DDTHH:mm:ss"),
      end_date: dayjs(values[1]).format("YYYY-MM-DDTHH:mm:ss"),
    }));
  };

  const configuration = {
    sunday: "D",
    monday: "L",
    tuesday: "M",
    wednesday: "Mi",
    thursday: "J",
    friday: "V",
    saturday: "S",
    ok: "Buscar",
    formattedMonthPattern: "MMMM yyyy",
  };

  const handleSidebarToggle = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  const canSeeAuth =
    user && (user.is_superuser || user.zona.nombre_zona === ROLES.ROSARIO);

  return (
    <div>
      <Header current={2} onSidebarToggle={handleSidebarToggle} />

      <ModalComponent
        showModal={showPhoto}
        message={""}
        imgData={photoDialog.url}
        duration={3000}
        onClose={() => {
          setShowPhoto(false);
        }}
      />

      <div
        className={`${main.mainContent} ${isSidebarCollapsed ? main.expanded : main.collapsed}`}
      >
        <div
          className={"d-flex align-items-center"}
          style={{ gap: "5px", marginBottom: "1rem" }}
        >
          <div
            style={{ flexShrink: "0", flexBasis: "100px", textAlign: "left" }}
            className={`${styles.listTitle} mt-3`}
          >
            Historial
          </div>
        </div>
        <div className={main.filterContainer}>
          <div className={`${main.filterItem}`}>
            <img src="/filter_icon.svg" alt="filtro" width="19px" />
            <div>Filtro</div>
          </div>
          <div style={{ width: "200px" }}>
            <MultiSelectInput
              label={"Todas las zonas"}
              options={zones?.map((x) => {
                return { id: x.id, name: x.nombre_zona };
              })}
              setForm={handleSetFormZone}
            />
          </div>
          <div style={{ width: "200px" }}>
            <CustomProvider locale={esES}>
              <DateRangePicker
                locale={configuration}
                showHeader={false}
                showOneCalendar
                ranges={[]}
                format="dd/MM/yyyy hh:mm"
                showMeridian={true}
                caretAs={IconCalendar}
                onChange={handleDateRange}
                placeholder="Seleccione un horario"
              />
            </CustomProvider>
          </div>
          <div style={{ width: "200px" }}>
            <MultiSelectInput
              label={"Todas las barreras"}
              options={gatesystem?.map((x) => {
                return { id: x.id, name: x.nombre };
              })}
              setForm={handleSetFormGate}
            />
          </div>

          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "700" }}>Total vehículos</span>
            <span
              style={{ marginLeft: "5px", color: "#8699CB", fontWeight: "600" }}
            >
              {totalVehicles}
            </span>
          </div>
        </div>

        <table className={`${styles.listTable} mt-3`}>
          <thead>
            <tr>
              <th style={{ minWidth: "200px" }}>Sistema de Barrera</th>
              <th className={`${styles.tableItemHeader}`}>Tipo</th>
              <th className={`${styles.tableItemHeader}`}>Matrícula</th>
              <th className={`${styles.tableItemHeader}`}>Placa de mina</th>
              {canSeeAuth && (
                <th className={`${styles.tableItemHeader}`}>Autorizado</th>
              )}
              <th className={`${styles.tableItemHeader}`}>Zonas</th>
              {(user.is_superuser || user.zona.nombre_zona === ROLES.UJINA) && (
                <th className={`${styles.tableItemHeader}`}>Última Apertura</th>
              )}
              {(user.is_superuser ||
                user.zona.nombre_zona === ROLES.ROSARIO) && (
                  <>
                    <th className={`${styles.tableItemHeader}`}>Entrada</th>
                    <th className={`${styles.tableItemHeader}`}>Salida</th>
                  </>
                )}
              <th className={`${styles.tableItemHeader}`}></th>
            </tr>
          </thead>
          <tbody>
            {history.map((item) => (
              <tr key={item.id}>
                <td style={{ minWidth: "120px" }}>{item.gate_nombre}</td>
                <td className={`${styles.tableItem}`}>{item.model}</td>
                <td className={`${styles.tableItem}`}>{item.license_plate}</td>
                <td className={`${styles.tableItem}`}>{item.mining_plate}</td>
                {canSeeAuth && item.authorized !== null && (
                  <td className={`${styles.tableItem}`}>
                    <AuthorizationBadge authorized={item.authorized} />
                  </td>
                )}
                {canSeeAuth && item.authorized === null && (
                  <td className={`${styles.tableItem}`}>-</td>
                )}
                <td className={`${styles.tableItem}`}>{item.zona_nombre}</td>
                {(user.is_superuser ||
                  user.zona.nombre_zona === ROLES.UJINA) && (
                    <td className={`${styles.tableItem}`}>
                      {dayjs(item.datetime).format("DD/MM/YYYY HH:mm A")}
                    </td>
                  )}
                {(user.is_superuser ||
                  user.zona.nombre_zona === ROLES.ROSARIO) && (
                    <>
                      <td className={`${styles.tableItem}`}>
                        {item.datetime_entry
                          ? dayjs(item.datetime_entry).format(
                            "DD/MM/YYYY HH:mm A",
                          )
                          : "-"}
                      </td>
                      <td className={`${styles.tableItem}`}>
                        {item.datetime_exit
                          ? dayjs(item.datetime_exit).format("DD/MM/YYYY HH:mm A")
                          : "-"}
                      </td>
                    </>
                  )}
                <td className={`${styles.tableItem}`}>
                  <div style={{ gap: "10px", display: "flex" }}>
                    <img
                      src={`${item.photo}`}
                      alt="placa"
                      style={{
                        width: "40px",
                        height: "30px",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        setPhotoDialog({ url: item.photo });
                        setShowPhoto(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <MyPaginate
          className="mt-3"
          breakLabel="..."
          nextLabel="Siguiente"
          onPageChange={(page) => setCurrentPage(page.selected + 1)}
          pageRangeDisplayed={3}
          pageCount={totalPages}
          previousLabel="Anterior"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}

const IconCalendar = () => {
  return <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>;
};
