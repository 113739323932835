// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".persons_tableContainer__wWRiN {\n  max-height: 80vh;\n  overflow-y: auto;\n  border-radius: 10px;\n  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);\n}\n\n.persons_tableHeader__h4sbc {\n  position: sticky;\n  top: 0;\n  background-color: #f8f9fa;\n  z-index: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/screens/persons/styles/persons.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,0CAA0C;AAC5C;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,yBAAyB;EACzB,UAAU;AACZ","sourcesContent":[".tableContainer {\n  max-height: 80vh;\n  overflow-y: auto;\n  border-radius: 10px;\n  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);\n}\n\n.tableHeader {\n  position: sticky;\n  top: 0;\n  background-color: #f8f9fa;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "persons_tableContainer__wWRiN",
	"tableHeader": "persons_tableHeader__h4sbc"
};
export default ___CSS_LOADER_EXPORT___;
