import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./App.css";
import RouteContainer from "./RouteContainter";
import store from "./store";
import { persistor } from "./store";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <RouteContainer />
          </Router>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
