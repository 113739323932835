// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_textDarkBlue__jxoBo {
    color: #162949;
    font-weight: bold;
}

.login_labelLeft__etY6A {
    text-align: left;
}

.login_borderDarkBlue__naKoX {
    border-color: #162949 !important;
}

.login_textLightBlue__AsQAi {
    color: #0BB6E8;
    text-decoration: none;
}

.login_passwordToggleIcon__nzt2F {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.login_formControl__AKktj {
    padding-right: 40px;
}

.login_loginButton__8MUGZ {
    margin: 0 15px;
    width: 80%;
    background-color: #162949 !important;
}

.login_passwordInputGroup__d\\+EYc {
    display: flex;
    align-items: center;
    position: relative;
}

.login_passwordInput__3WIzt {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.login_passwordToggle__AR1-B {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.login_passwordToggleIcon__nzt2F {
    font-size: 1.2rem;
}

input[type="password"] {
    border-radius: 10px;
    border: 2px solid #92A5D8;
    padding: 0.5rem;
    color: #92A5D8;
    /*margin-top: 0.5rem;*/
}`, "",{"version":3,"sources":["webpack://./src/styles/login.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,UAAU;IACV,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;AACjC;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,SAAS;IACT,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,cAAc;IACd,sBAAsB;AAC1B","sourcesContent":[".textDarkBlue {\n    color: #162949;\n    font-weight: bold;\n}\n\n.labelLeft {\n    text-align: left;\n}\n\n.borderDarkBlue {\n    border-color: #162949 !important;\n}\n\n.textLightBlue {\n    color: #0BB6E8;\n    text-decoration: none;\n}\n\n.passwordToggleIcon {\n    cursor: pointer;\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n}\n\n.formControl {\n    padding-right: 40px;\n}\n\n.loginButton {\n    margin: 0 15px;\n    width: 80%;\n    background-color: #162949 !important;\n}\n\n.passwordInputGroup {\n    display: flex;\n    align-items: center;\n    position: relative;\n}\n\n.passwordInput {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n}\n\n.passwordToggle {\n    cursor: pointer;\n    position: absolute;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n}\n\n.passwordToggleIcon {\n    font-size: 1.2rem;\n}\n\ninput[type=\"password\"] {\n    border-radius: 10px;\n    border: 2px solid #92A5D8;\n    padding: 0.5rem;\n    color: #92A5D8;\n    /*margin-top: 0.5rem;*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textDarkBlue": `login_textDarkBlue__jxoBo`,
	"labelLeft": `login_labelLeft__etY6A`,
	"borderDarkBlue": `login_borderDarkBlue__naKoX`,
	"textLightBlue": `login_textLightBlue__AsQAi`,
	"passwordToggleIcon": `login_passwordToggleIcon__nzt2F`,
	"formControl": `login_formControl__AKktj`,
	"loginButton": `login_loginButton__8MUGZ`,
	"passwordInputGroup": `login_passwordInputGroup__d+EYc`,
	"passwordInput": `login_passwordInput__3WIzt`,
	"passwordToggle": `login_passwordToggle__AR1-B`
};
export default ___CSS_LOADER_EXPORT___;
