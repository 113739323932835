import React, { useEffect, useState } from "react";

import Header from "../../components/header";
import { MenuButton, TextInput } from "../../components/input/inputs";
import Modal from "../../components/modal/modal";
import styles from "../../styles/authorizations.module.css";
import userStyles from "../users/styles/list.module.css";
import main from "../../styles/main.module.css";
import axiosInstance from "../../AxiosInstance";
import personStyles from "./styles/persons.module.css";
import MyPaginate from "../../components/react-pagination";
import AuthorizationBadge from "../../components/authorization_badge";

const INITIAL_FORM = {
  id: null,
  nombre: "",
  apellido: "",
  correo: "",
  autorizado: false,
  numero_telefono: "",
};

export default function PersonList() {
  const [paginatedData, setPaginatedData] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [form, setForm] = useState(INITIAL_FORM);
  const [file, setFile] = useState(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    const savedSidebarState = localStorage.getItem("isSidebarCollapsed");
    return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
  });
  const [itemMenuOpen, setItemMenuOpen] = useState({
    idUser: null,
  });
  const [errorMessages, setErrorMessages] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [addOrEdit, setAddOrEdit] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userList = await getPaginatedData(currentPage, searchTerm);
        setPaginatedData(userList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, searchTerm]);

  const getPaginatedData = (page = 1, search = "") =>
    axiosInstance
      .get(`personas/?page=${page}&search=${search}`)
      .then((response) => {
        setTotalPages(response.data.total_pages);
        setCurrentPage(response.data.current_page);
        return response.data;
      });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      selectedFile.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      alert("Por favor, selecciona un archivo Excel (.xlsx).");
    }
  };

  const handleUploadFile = async () => {
    if (loading) return;
    if (!file) {
      alert("Por favor, selecciona un archivo para subir.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      await axiosInstance.post("personas/import-export-personas/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setFile(null);
      setShowUploadModal(false);
      const paginatedData = await getPaginatedData();
      setPaginatedData(paginatedData);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error al subir el archivo. Por favor, intenta de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadFile = async () => {
    try {
      let url = "personas/import-export-personas/";
      if (selectedIds.length > 0) {
        const idsQuery = selectedIds.join(",");
        url += `?ids=${idsQuery}`;
      }

      const response = await axiosInstance.get(url, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Personal.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Error al descargar el archivo. Por favor, intenta de nuevo.");
    }
  };

  const handleSidebarToggle = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  const validateForm = () => {
    let errors = {};
    if (!form.id) errors.id = "El documento de identidad es obligatorio.";
    if (!form.nombre) errors.first_name = "El nombre es obligatorio.";
    if (!form.apellido) errors.last_name = "El apellido es obligatorio.";
    if (!form.correo) errors.email = "El correo electrónico es obligatorio.";
    if (!form.numero_telefono)
      errors.numero_telefono = "El número de teléfono es obligatorio.";

    return errors;
  };

  const handleForm = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      setGeneralError("Por favor, complete todos los campos obligatorios.");
      return;
    }

    const url = addOrEdit ? `personas/${form.id}/` : "personas/";
    const method = addOrEdit ? "patch" : "post";

    try {
      await axiosInstance[method](url, form);
      const paginatedData = await getPaginatedData();
      setPaginatedData(paginatedData);
      setShowCreateModal(false);
      resetForm();
      setErrorMessages({});
      setGeneralError("");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessages(error.response.data);
      } else {
        console.error("Error handling form submission:", error);
      }
    }
  };

  const handleCancelCreate = () => {
    resetForm();
  };

  const handleItemMenu = (id) => {
    setItemMenuOpen({
      idUser: itemMenuOpen.idUser === id ? null : id,
    });
  };

  const handleEditUser = (person) => {
    setAddOrEdit(1);
    setShowCreateModal(true);
    setForm({
      id: person.id,
      nombre: person.nombre,
      apellido: person.apellido,
      correo: person.correo,
      autorizado: person.autorizado,
      numero_telefono: person.numero_telefono,
      id_licencia: person.id_licencia,
      id_solicitud: person.id_solicitud,
      razon_social: person.razon_social,
      fecha_registro: person.fecha_registro,
      fecha_vencimiento: person.fecha_vencimiento,
      area_conduccion: person.area_conduccion,
      tipo_licencia: person.tipo_licencia,
    });
  };

  const handleDeleteUser = (person) => {
    setShowDeleteModal(true);
    setForm({
      id: person.id,
      nombre: person.nombre,
      apellido: person.apellido,
      correo: person.correo,
      autorizado: person.autorizado,
      numero_telefono: person.numero_telefono,
      id_licencia: person.id_licencia,
      id_solicitud: person.id_solicitud,
      razon_social: person.razon_social,
      fecha_registro: person.fecha_registro,
      fecha_vencimiento: person.fecha_vencimiento,
      area_conduccion: person.area_conduccion,
      tipo_licencia: person.tipo_licencia,
    });
  };

  const handleConfirmDelete = async () => {
    const { id } = form;
    try {
      await axiosInstance.delete(`personas/${id}/`);
      const userList = await getPaginatedData();
      setPaginatedData(userList);
      setShowDeleteModal(false);
      resetForm();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const resetForm = () => {
    setForm(INITIAL_FORM);
    setErrorMessages({});
    setGeneralError("");
  };

  const handleSelectId = (id) => {
    setSelectedIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((selectedId) => selectedId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedIds([]);
    } else {
      const allIds = paginatedData.results.map((person) => person.id);
      setSelectedIds(allIds);
    }
    setAllSelected(!allSelected);
  };

  const processEntry = (entry) => {
    if (!entry || entry === "nan") {
      return "-";
    } else {
      return entry;
    }
  };

  return (
    <div
      className={`${main.mainContent} ${isSidebarCollapsed ? main.expanded : main.collapsed}`}
    >
      <Header current={5} onSidebarToggle={handleSidebarToggle} />

      <Modal
        show={showUploadModal}
        setter={setShowUploadModal}
        name="Subir archivo Excel"
        buttonName="Subir"
        onClickCancel={() => setShowUploadModal(false)}
        onClick={handleUploadFile}
      >
        <div className="container-fluid">
          <div className="form-group">
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div className="form-group">
            <p>
              Puedes descargar la plantilla del archivo{" "}
              <a href="/TranquerasPersonasPlantilla.xlsx" download>
                aquí
              </a>
              .
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        show={showCreateModal}
        setter={setShowCreateModal}
        name={`${addOrEdit ? "Editar" : "Agregar"} Usuario`}
        buttonName={addOrEdit ? "Guardar" : "Agregar"}
        onClickCancel={handleCancelCreate}
        onClick={handleForm}
      >
        <div className="container-fluid">
          {generalError && (
            <div className="alert alert-danger">{generalError}</div>
          )}

          {/* Campos existentes */}
          <div className="form-group">
            <TextInput
              name={"Documento de Identidad"}
              form={form}
              setForm={setForm}
              internalName={"id"}
              errorMessage={errorMessages.documento_identidad}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Nombre"}
              form={form}
              setForm={setForm}
              internalName={"nombre"}
              errorMessage={errorMessages.first_name}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Apellido"}
              form={form}
              setForm={setForm}
              internalName={"apellido"}
              errorMessage={errorMessages.last_name}
            />
          </div>
          <div className="form-group">
            <div className="mb-1 d-flex">
              <div style={{ flexBasis: "28.5%" }}>Autorizado</div>
              <input
                type="checkbox"
                checked={form.autorizado}
                onChange={(e) =>
                  setForm({ ...form, autorizado: e.target.checked })
                }
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>
          <div className="form-group">
            <TextInput
              name={"Correo"}
              form={form}
              setForm={setForm}
              internalName={"correo"}
              errorMessage={errorMessages.email}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Número de Teléfono"}
              form={form}
              setForm={setForm}
              internalName={"numero_telefono"}
              errorMessage={errorMessages.numero_telefono}
            />
          </div>

          {/* Campos nuevos */}
          <div className="form-group">
            <TextInput
              name={"ID Licencia"}
              form={form}
              setForm={setForm}
              internalName={"id_licencia"}
              errorMessage={errorMessages.id_licencia}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"ID Solicitud"}
              form={form}
              setForm={setForm}
              internalName={"id_solicitud"}
              errorMessage={errorMessages.id_solicitud}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Razón Social"}
              form={form}
              setForm={setForm}
              internalName={"razon_social"}
              errorMessage={errorMessages.razon_social}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Fecha Registro"}
              form={form}
              setForm={setForm}
              internalName={"fecha_registro"}
              errorMessage={errorMessages.fecha_registro}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Fecha Vencimiento"}
              form={form}
              setForm={setForm}
              internalName={"fecha_vencimiento"}
              errorMessage={errorMessages.fecha_vencimiento}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Área Conducción"}
              form={form}
              setForm={setForm}
              internalName={"area_conduccion"}
              errorMessage={errorMessages.area_conduccion}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Tipo Licencia"}
              form={form}
              setForm={setForm}
              internalName={"tipo_licencia"}
              errorMessage={errorMessages.tipo_licencia}
            />
          </div>
        </div>
      </Modal>

      <Modal
        show={showDeleteModal}
        setter={setShowDeleteModal}
        name={"Eliminar Usuario"}
        buttonName={"Eliminar"}
        onClickCancel={handleCancelCreate}
        onClick={handleConfirmDelete}
      >
        <div>
          <div className={"d-flex flex-row align-items-center w-100"}>
            {`¿Está seguro que desea eliminar al usuario ${form.nombre} ${form.apellido}?`}
          </div>
        </div>
      </Modal>

      <div className={"bg-light vh-100 mt-3"}>
        <div className={`${styles.createContainer}`}>
          <div
            className={"d-flex align-items-center"}
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <div className={`${styles.listTitle}`}>Lista de Personas</div>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <button
                  className={`btn ${main.mainButton}`}
                  onClick={() => {
                    setShowCreateModal(true);
                    setAddOrEdit(0);
                  }}
                >
                  Agregar
                </button>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className={`btn ${main.mainButton}`}
                  onClick={handleDownloadFile}
                >
                  Descargar
                </button>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className={`btn ${main.mainButton}`}
                  onClick={() => setShowUploadModal(true)}
                >
                  Subir
                </button>
              </div>
              <div className="d-flex align-items-center">
                <input
                  className="inputBorderDefault form-control rounded-2"
                  placeholder="Buscar"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={personStyles.tableContainer}>
          <table className={`${styles.listTable}`}>
            <thead>
              <tr>
                <th className={personStyles.tableHeader}>
                  <input
                    type="checkbox"
                    checked={allSelected}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className={personStyles.tableHeader}>DNI</th>
                <th className={personStyles.tableHeader}>Nombre</th>
                <th className={personStyles.tableHeader}>Apellido</th>
                <th className={personStyles.tableHeader}>Autorizado</th>
                <th className={personStyles.tableHeader}>Correo</th>
                <th className={personStyles.tableHeader}>Teléfono</th>
                <th className={personStyles.tableHeader}>ID Licencia</th>
                <th className={personStyles.tableHeader}>ID Solicitud</th>
                <th className={personStyles.tableHeader}>Razón Social</th>
                <th className={personStyles.tableHeader}>Fecha Registro</th>
                <th className={personStyles.tableHeader}>Fecha Vencimiento</th>
                <th className={personStyles.tableHeader}>Área Conducción</th>
                <th className={personStyles.tableHeader}>Tipo Licencia</th>
                <th className={personStyles.tableHeader}></th>
              </tr>
            </thead>
            <tbody>
              {paginatedData &&
                paginatedData.results.map((person) => (
                  <tr key={person.id}>
                    <td className={styles.tableItem}>
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(person.id)}
                        onChange={() => handleSelectId(person.id)}
                      />
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.id)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.nombre)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.apellido)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      <AuthorizationBadge authorized={person.autorizado} />
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.correo)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.numero_telefono)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.id_licencia)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.id_solicitud)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.razon_social)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.fecha_registro)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.fecha_vencimiento)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.area_conduccion)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(person.tipo_licencia)}
                    </td>

                    <td className={`${styles.tableItem}`}>
                      <MenuButton onClick={() => handleItemMenu(person.id)}>
                        {itemMenuOpen.idUser === person.id && (
                          <div className={`${userStyles.dropdown}`}>
                            <div
                              onClick={() => handleEditUser(person)}
                              className={`${userStyles.dropdownItem}`}
                            >
                              Editar
                            </div>
                            <div
                              onClick={() => handleDeleteUser(person)}
                              className={`${userStyles.dropdownItem}`}
                            >
                              Eliminar
                            </div>
                          </div>
                        )}
                      </MenuButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="mt-3">
          <MyPaginate
            breakLabel="..."
            nextLabel="Siguiente"
            onPageChange={(page) => setCurrentPage(page.selected + 1)}
            pageRangeDisplayed={3}
            pageCount={totalPages}
            previousLabel="Anterior"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  );
}
