import { faChevronDown, faChevronUp, faCloudArrowUp, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styles from './inputs.module.css';

import esES from 'rsuite/locales/es_ES';

import { CustomProvider, DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

function TextInput({ name, internalName, placeholder, form, setForm, hideName, password, noMargin, type, options, multiple }) {
    const [formName, setFormName] = useState(internalName);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
        }
    }, [name, formName]);

    const setField = (value) => {
        if (multiple) {
            setForm({
                ...form,
                [formName]: Array.from(value)
            });
        } else {
            setForm({
                ...form,
                [formName]: value
            });
        }
    };

    const handleFileChange = (event) => {
        setForm({
            ...form,
            [formName]: event.target.files[0]
        });
    };

    return (
        <div className={`form-group ${!noMargin && 'mb-3 d-flex align-items-center'}`}>
            {!hideName && <div className="inputLabel mb-1" style={{ flexBasis: '40%' }}>{name}</div>}
            {type === 'select' ? (
                <select
                    className="form-control rounded-2"
                    id={name}
                    value={multiple ? form[formName] || [] : form[formName] || ''}
                    onChange={(event) => setField(multiple ? Array.from(event.target.selectedOptions, option => option.value) : event.target.value)}
                    multiple={multiple}
                >
                    <option value="">{placeholder || 'Selecciona una opción'}</option>
                    {options && options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            ) : type === 'file' ? (
                <input
                    type="file"
                    className="form-control rounded-2"
                    id={name}
                    onChange={handleFileChange}
                />
            ) : (
                <input
                    type={password ? 'password' : 'text'}
                    className="inputBorderDefault form-control rounded-2"
                    id={name}
                    placeholder={placeholder}
                    value={form[formName] || ''}
                    onChange={(event) => setField(event.target.value)}
                />
            )}
        </div>
    );
}





function BigTextInput({ name, internalName, placeholder, form, setForm }) {
    const [formName, setFormName] = useState(internalName);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
        }
    }, []);

    const setField = (value) => {
        setForm({
            ...form,
            [formName]: value
        });
    }

    return (
        <div className="form-group mb-3">
            <div className={`${styles.inputLabel} mb-1`}>{name}</div>
            <textarea
                className={`${styles.inputBorderDefault} form-control rounded-2`}
                id={name}
                placeholder={placeholder}
                value={form[formName]}
                onChange={(event) => setField(event.target.value)}
            />
        </div>
    )
}

function SelectInput({ name, internalName, options, form, setForm, noText, hideName, needSearch, placeholder }) {
    const [formName, setFormName] = useState(internalName);
    const [valueName, setValueName] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [internalUpdate, setInternalUpdate] = useState(false);
    const [optionsFiltered, setOptionsFiltered] = useState(options);

    useEffect(() => {
        if (!internalUpdate) {
            const filtered = options.filter((option) => option.id === form[formName]);
            setValueName(filtered[0]?.name);
        }
        setInternalUpdate(false);
    }, [form[formName]]);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
            setValueName(options[form[name]]?.name)

        } else {
            setValueName(options.find((option) => form[formName] == option.id)?.name)
        }
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const setField = (value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [formName]: value,
        }));
        setIsOpen(false);
    };

    const setValue = (name, value) => {
        setInternalUpdate(true);
        setValueName(name);
        setField(value)
    }

    const handleSearch = (event) => {
        const text = event.target.value
        const filteredOptions = options.filter((value) => value.name.toLowerCase().includes(text.toLowerCase()))
        setOptionsFiltered(filteredOptions)
    }

    return (
        <div className={`${styles.customSelect}  d-flex align-items-center`} ref={dropdownRef} style={{ userSelect: 'none' }}>
            {
                name && <div className={`${styles.inputLabel}`} style={{ flexBasis: '30%' }}>{name}</div>
            }

            <div style={{ flexBasis: name ? '70%' : '100%', height: "100%" }}>
                <div
                    className={styles.dropdownButton}
                    onClick={toggleDropdown}
                >
                    <div>{valueName || (noText ? '-' : placeholder ?? 'Select an option')}</div>
                    {isOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                </div>
                <div className={`${styles.dropdownOptions} ${isOpen ? styles.show : ''}`} style={{ width: name ? '70%' : '100%' }}>
                    <div style={{ width: '100%' }}>
                        {needSearch && <div className={`${styles.selectSearch}`}>
                            <input placeholder="Buscar" className={`${styles.inputSelectSearch}`} type="text" name="searchSelect" onChange={handleSearch} />
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </div>}
                        <div style={{ overflow: 'auto', height: needSearch ? "calc(100% - 42px)" : "100%" }}>
                            {optionsFiltered && optionsFiltered.map((option, index) => (
                                <div
                                    key={index}
                                    className={`${form[formName] === option.id ? styles.selected : ''}`}
                                    onClick={() => setValue(option.name, option.id)}
                                >
                                    {option.name}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function RadioInput({ name, internalName, options, form, setForm, hideName, inline = false }) {
    const [formName, setFormName] = useState(internalName);
    const [checked, setChecked] = useState(form[formName]);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
            setChecked(form[name]);
        } else {
            setChecked(form[internalName]);
            console.log('in');
            console.log(form[internalName]);
        }
    }, []);

    const setField = (value) => {
        setForm({
            ...form,
            [formName]: value
        });
    };

    const handleRadioChange = (index) => {
        setChecked(index);
        setField(index);
    };

    return (
        <div className="form-group mb-3">
            {!hideName && <div className={`${styles.inputLabel} mb-1`}>{name}</div>}
            <div className={`${styles.customRadio} ${inline ? styles.inline : ''}`}>
                {options.map((option, index) => (
                    <div key={index} className={styles.radioOption}>
                        <input
                            type="radio"
                            id={`${name}-${index}`}
                            name={name}
                            checked={checked === index}
                            onChange={() => handleRadioChange(index)}
                        />
                        <label htmlFor={`${name}-${index}`}>{option}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

function FileInput({ name, internalName, form, setForm }) {
    const [formName, setFormName] = useState(internalName);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
        }
    }, []);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
        setForm({
            ...form,
            [formName]: event.target.files[0]
        });
    };

    const handleFileClick = () => {
        fileInputRef.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setSelectedFiles(files);
        setForm({
            ...form,
            [formName]: event.dataTransfer.files[0]
        });
    };

    return (
        <div className={styles.fileInputOutsideContainer}>
            <div
                className={`${styles.fileInputContainer} py-4`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <div
                    className={styles.fileInput}
                    onClick={handleFileClick}
                >
                    <div className={styles.uploadText}>
                        <FontAwesomeIcon icon={faCloudArrowUp} size={'2xl'} color={'#6B7280'} />
                        <div className={'d-flex'}>
                            <div className={styles.defaultColorText}>Upload an image</div>or drag and drop
                        </div>
                        <div>PNG, JPG</div>
                    </div>
                </div>
                <input
                    type="file"
                    accept=".png,.jpg"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
                {selectedFiles.length > 0 && (
                    <div className={`${styles.fileList}`}>
                        {selectedFiles.map((file, index) => (
                            <div className={styles.fileItem} key={index}>
                                <img
                                    className={styles.previewImage}
                                    src={URL.createObjectURL(file)}
                                    alt="Preview"
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

function LogoInput({ name, internalName, form, setForm }) {
    const [formName, setFormName] = useState(internalName);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
        }
    }, []);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
        setForm({
            ...form,
            [formName]: event.target.files[0]
        });
    };

    const handleFileClick = () => {
        fileInputRef.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setSelectedFiles(files);
        setForm({
            ...form,
            [formName]: event.dataTransfer.files[0]
        });
    };

    return (
        <div className={styles.fileInputOutsideContainer}>
            <div
                className={`${styles.fileInputContainer}`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <input
                    type="file"
                    accept=".png,.jpg"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
                {selectedFiles.length > 0 ? (
                    <div className={`${styles.fileList}`}>
                        {selectedFiles.map((file, index) => (
                            <div
                                className={styles.fileInput}
                                onClick={handleFileClick}
                            >
                                <div className={styles.uploadText}>
                                    <div className={styles.fileItem} key={index}>
                                        <img
                                            className={styles.previewImage}
                                            src={URL.createObjectURL(file)}
                                            alt="Preview"
                                        />
                                    </div>
                                    <div>
                                        <div className={`${styles.defaultColorText} d-flex ps-3`}>Add Picture</div>
                                        <div>[Max: 1MB]</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) :
                    <div
                        className={styles.fileInput}
                        onClick={handleFileClick}
                    >
                        <div className={styles.uploadText}>
                            <img src={'/mid_cloud.png'} alt={'cloud'} height={'80px'} />
                            <div>
                                <div className={`${styles.defaultColorText} d-flex ps-3`}>Add Picture</div>
                                <div>[Max: 1MB]</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

function DateInput({ name, internalName, form, setForm }) {
    const [formName, setFormName] = useState(internalName);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
        }
    }, []);

    const setField = (value) => {
        setForm({
            ...form,
            [formName]: value
        });
    }

    return (
        <div className="form-group mb-3">
            <div className={`${styles.inputLabel} mb-1`}>{name}</div>
            <input
                type={'date'}
                className={`${styles.inputBorderDefault} form-control rounded-2`}
                id={name}
                value={form[formName]}
                onChange={(event) => setField(event.target.value)}
            />
        </div>
    )
}

function PrimaryButton({ name, onClick }) {
    return (
        <div className={styles.primaryButton} onClick={onClick}>
            <div>{name}</div>
        </div>
    );
}

function SecondaryButton({ name, onClick }) {
    return (
        <div className={styles.secondaryButton} onClick={onClick}>
            <div>{name}</div>
        </div>
    );
}

function DangerButton({ name, onClick }) {
    return (
        <div className={styles.dangerButton} onClick={onClick}>
            <div>{name}</div>
        </div>
    );
}

function DateRangeInput({ }) {
    const configuration = {
        sunday: 'D',
        monday: 'L',
        tuesday: 'M',
        wednesday: 'Mi',
        thursday: 'J',
        friday: 'V',
        saturday: 'S',
        ok: 'Buscar',
        formattedMonthPattern: 'MMMM yyyy',
    };

    return (
        <>
            <CustomProvider locale={esES}>
                <DateRangePicker
                    locale={configuration}
                    showHeader={false}
                    showOneCalendar
                    ranges={[]}
                    placeholder="Seleccione un rango"
                />
            </CustomProvider>
        </>
    );
}

function MultiSelectInput({ label, options, setForm }) {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([])

    const multiSelectRef = useRef(null)
    const handleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (event) => {
        if (
            multiSelectRef.current &&
            !multiSelectRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    const handleChangeOptions = (event) => {
        const { name, value, checked } = event.target
        const idValue = Number(value)
        if (checked) {

            setSelectedOptions((prev) => {
                const exists = prev.find((opt) => opt === idValue)
                if (!exists) {
                    const newOptions = [...prev, idValue]
                    setForm(newOptions)
                    console.log(newOptions)
                    return newOptions
                }
                return prev
            })
        } else {
            setSelectedOptions((prev) => {
                const newOptions = prev.filter((opt) => opt !== idValue)
                setForm(newOptions)
                console.log(newOptions)
                return newOptions
            })
        }

    }

    return (
        <div className={`${styles.multiSelectInputContainer}`} ref={multiSelectRef}  >
            <div className={`${styles.multiSelectInput}`} onClick={handleIsOpen} >
                <div>{(selectedOptions.length === 0 || options.length == 0) ? label : `${selectedOptions.length} seleccionados`}</div>
                {isOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </div>

            {
                isOpen && <div className={`${styles.multiSelectOptions}`} >
                    <div className={`${styles.multiSelectOptionsContainer}`}>
                        {
                            options.map(({ id, name }) => (
                                <div key={id} className={`${styles.multiSelectOption}`} >
                                    <input checked={selectedOptions.includes(id)} onChange={handleChangeOptions} type="checkbox" name={`opcion-${id}`} value={id} id={`opcion-${id}`} className="form-check-input" />
                                    <label htmlFor={`opcion-${id}`} style={{ flexGrow: '1', textAlign: 'left', cursor: 'pointer' }}>{name}</label>
                                </div>
                            ))
                        }


                    </div>
                </div>
            }

        </div>
    )
}

function MenuButton({ children, onClick }) {

    const [isOpen, setIsOpen] = useState(false)
    const menuRef = useRef(null)

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    const handleToggleMenu = () => {
        setIsOpen(true)
        onClick()
    }

    return (
        <div className="position-relative" ref={menuRef}>
            <img src="/menu_button.svg" alt="menu" onClick={() => handleToggleMenu()} style={{ cursor: 'pointer' }} />
            {isOpen && <div >
                {children}
            </div>}
        </div>
    )
}

export {
    BigTextInput, DangerButton, DateInput, DateRangeInput, FileInput, LogoInput, MenuButton, MultiSelectInput, PrimaryButton, RadioInput, SecondaryButton, SelectInput, TextInput
};

