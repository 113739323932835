// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vehicles_tableContainer__h096i {
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .vehicles_tableHeader__LyFmQ {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/vehicles/styles/vehicles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,0CAA0C;EAC5C;;EAEA;IACE,gBAAgB;IAChB,MAAM;IACN,yBAAyB;IACzB,UAAU;EACZ","sourcesContent":[".tableContainer {\n    max-height: 80vh;\n    overflow-y: auto;\n    border-radius: 10px;\n    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);\n  }\n  \n  .tableHeader {\n    position: sticky;\n    top: 0;\n    background-color: #f8f9fa;\n    z-index: 1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `vehicles_tableContainer__h096i`,
	"tableHeader": `vehicles_tableHeader__LyFmQ`
};
export default ___CSS_LOADER_EXPORT___;
