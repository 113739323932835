// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Archivo CSS modificado */

.type_chooser_unselected__WSxVY {
    background-color: #FFF;
    color: #36496D;
    border: #36496D 1px solid;
    border-radius: 6px;
    height: 40px;
    width: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    font-weight: 700;
}

.type_chooser_selected__io93M {
    background-color: #00A5E2;
    color: #FFFFFF;
    height: 40px;
    width: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 6px;
    margin-left: 10px;
    cursor: pointer;
}

.type_chooser_add__C7e-k {
    color: #8699CB;
    border: #8699CB 1px solid;
    width: 120px;
    height: 40px;
    background-color: #F6F7FC;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Nuevo estilo para el contenedor de imágenes */
.type_chooser_image-scroll-container__MLgs- {
    overflow-y: scroll;
    max-height: calc(100vh - 400px); /* Ajusta según el diseño y el espacio disponible */
    width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/entities/chooser/type_chooser.module.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;IACI,sBAAsB;IACtB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA,gDAAgD;AAChD;IACI,kBAAkB;IAClB,+BAA+B,EAAE,mDAAmD;IACpF,YAAY;AAChB","sourcesContent":["/* Archivo CSS modificado */\n\n.unselected {\n    background-color: #FFF;\n    color: #36496D;\n    border: #36496D 1px solid;\n    border-radius: 6px;\n    height: 40px;\n    width: 110px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    margin-left: 10px;\n    cursor: pointer;\n    font-weight: 700;\n}\n\n.selected {\n    background-color: #00A5E2;\n    color: #FFFFFF;\n    height: 40px;\n    width: 110px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    border-radius: 6px;\n    margin-left: 10px;\n    cursor: pointer;\n}\n\n.add {\n    color: #8699CB;\n    border: #8699CB 1px solid;\n    width: 120px;\n    height: 40px;\n    background-color: #F6F7FC;\n    border-radius: 6px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n}\n\n/* Nuevo estilo para el contenedor de imágenes */\n.image-scroll-container {\n    overflow-y: scroll;\n    max-height: calc(100vh - 400px); /* Ajusta según el diseño y el espacio disponible */\n    width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unselected": `type_chooser_unselected__WSxVY`,
	"selected": `type_chooser_selected__io93M`,
	"add": `type_chooser_add__C7e-k`,
	"image-scroll-container": `type_chooser_image-scroll-container__MLgs-`
};
export default ___CSS_LOADER_EXPORT___;
