// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_loadingSpinner__-q2NY {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.modal_spinner__I\\+OyQ {
    position: relative;
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    animation: modal_spin__6zM\\+j 2s linear infinite;
    text-align: center;
}

.modal_text__ozfVE {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes modal_spin__6zM\\+j {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.modal_bgimg__PQieK {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: auto;
}

.modal_welcomeContainer__inNEF {
    display: flex;
}

.modal_welcomeText__RLaAJ {
    color: #323659;
}

.modal_fontBig__R5Jkf {
    font-size: 100px;
    font-weight: bold;
}

.modal_absButton__qgLA5 {
    position: absolute;
    top: 30px;
    right: 30px;
}`, "",{"version":3,"sources":["webpack://./src/screens/states/styles/modal.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;IAC1B,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,gDAAkC;IAClC,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf","sourcesContent":[".loadingSpinner {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: 24px;\n}\n\n.spinner {\n    position: relative;\n    border: 16px solid #f3f3f3;\n    border-top: 16px solid #3498db;\n    border-radius: 50%;\n    width: 200px;\n    height: 200px;\n    animation: spin 2s linear infinite;\n    text-align: center;\n}\n\n.text {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n.bgimg {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100vw;\n    height: auto;\n}\n\n.welcomeContainer {\n    display: flex;\n}\n\n.welcomeText {\n    color: #323659;\n}\n\n.fontBig {\n    font-size: 100px;\n    font-weight: bold;\n}\n\n.absButton {\n    position: absolute;\n    top: 30px;\n    right: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSpinner": `modal_loadingSpinner__-q2NY`,
	"spinner": `modal_spinner__I+OyQ`,
	"spin": `modal_spin__6zM+j`,
	"text": `modal_text__ozfVE`,
	"bgimg": `modal_bgimg__PQieK`,
	"welcomeContainer": `modal_welcomeContainer__inNEF`,
	"welcomeText": `modal_welcomeText__RLaAJ`,
	"fontBig": `modal_fontBig__R5Jkf`,
	"absButton": `modal_absButton__qgLA5`
};
export default ___CSS_LOADER_EXPORT___;
