import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { login } from "../features/auth/authActions";
import styles from "../styles/login.module.css";

export default function LoginScreen() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submit = async (event) => {
    try {
      event.preventDefault();
      await dispatch(login({ username: username, password: password }));
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="container-fluid bg-light vh-100"
      style={{ userSelect: "none" }}
    >
      <div className="row justify-content-center align-items-center h-100">
        <div
          className="col-md-6 col-lg-3 rounded p-4 bg-white shadow"
          style={{ minWidth: "500px" }}
        >
          <img
            src={"./logo_stracon_azul.png"}
            alt="Company Logo"
            className="img-fluid mb-4"
          />
          <form onSubmit={submit}>
            <div className="form-group">
              <div
                className={`${styles.textDarkBlue} ${styles.labelLeft} mb-1 mt-4`}
              >
                Usuario
              </div>
              <input
                type="text"
                className={`form-control ${styles.borderDarkBlue} rounded-2`}
                id="username"
                placeholder="Usuario"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
            </div>
            <div className="form-group">
              <div
                className={`${styles.textDarkBlue} ${styles.labelLeft}  mb-1 mt-4`}
              >
                Contraseña
              </div>
              <div className={`input-group ${styles.passwordInputGroup}`}>
                <input
                  type={passwordVisible ? "text" : "password"}
                  className={`form-control ${styles.borderDarkBlue} rounded-2 ${styles.passwordInput}`}
                  id="password"
                  placeholder="Contraseña"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <div
                  style={{ width: "46px", zIndex: "9999", borderWidth: "2px" }}
                  className={`input-group-text bg-white ${styles.borderDarkBlue} ${styles.passwordToggle}`}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4">
              <span>Olvidé mi contraseña</span>
            </div>
            <div className="form-group align-items-center mb-3">
              <a href="/login" className={`${styles.textLightBlue}`}>
                Recuperar
              </a>
            </div>
            <button
              type="submit"
              className={`btn text-white rounded-2 px-4 ${styles.loginButton}`}
            >
              Iniciar Sesión
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
