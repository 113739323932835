import React, { useEffect, useState } from "react";

import Header from "../../components/header";
import { DatePickerInput, MenuButton, TextInput } from "../../components/input/inputs";
import Modal from "../../components/modal/modal";
import vehicleStyles from "./styles/vehicles.module.css";
import styles from "../../styles/authorizations.module.css";
import userStyles from "../users/styles/list.module.css";
import main from "../../styles/main.module.css";
import axiosInstance from "../../AxiosInstance";
import MyPaginate from "../../components/react-pagination";
import AuthorizationBadge from "../../components/authorization_badge";
import { formatISO, format } from "date-fns";

const INITIAL_FORM = {
  numero_letrero_metalico: "",
  patente: "",
  tipo: "",
  nombre_dueño: "",
  ubicacion_comentario: "",
  solicitada_por: "",
  fecha_termino_contrato: "",
  fecha_entrega_numero: "",
  observaciones: "",
  autorizador: "",
  observaciones_otorgamiento: "",
};

export default function PersonList() {
  const [paginatedData, setPaginatedData] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [form, setForm] = useState(INITIAL_FORM);
  const [file, setFile] = useState(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    const savedSidebarState = localStorage.getItem("isSidebarCollapsed");
    return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
  });
  const [itemMenuOpen, setItemMenuOpen] = useState({
    idUser: null,
  });
  const [errorMessages, setErrorMessages] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [addOrEdit, setAddOrEdit] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userList = await getPaginatedData(currentPage, searchTerm);
        setPaginatedData(userList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, searchTerm]);

  const getPaginatedData = (page = 1, search = "") =>
    axiosInstance
      .get(`vehiculos/?page=${page}&search=${search}`)
      .then((response) => {
        setTotalPages(response.data.total_pages);
        setCurrentPage(response.data.current_page);
        return response.data;
      });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
    } else {
      alert("Por favor, selecciona un archivo Excel (.xlsx).");
    }
  };

  const handleUploadFile = async () => {
    if (loading) return;
    if (!file) {
      alert("Por favor, selecciona un archivo para subir.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      await axiosInstance.post("vehiculos/import-export-vehiculos/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setFile(null);
      setShowUploadModal(false);
      const paginatedData = await getPaginatedData();
      setPaginatedData(paginatedData);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error al subir el archivo. Por favor, intenta de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadFile = async () => {
    try {
      let url = "vehiculos/import-export-vehiculos/";
      if (selectedIds.length > 0) {
        const idsQuery = selectedIds.join(",");
        url += `?ids=${idsQuery}`;
      }

      const response = await axiosInstance.get(url, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Vehículos.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Error al descargar el archivo. Por favor, intenta de nuevo.");
    }
  };

  const handleSidebarToggle = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  const validateForm = () => {
    let errors = {};
    if (!form.numero_letrero_metalico)
      errors.numero_letrero_metalico = "El número de letrero metálico es obligatorio.";
    if (!form.patente) 
      errors.patente = "La patente es obligatoria.";
    if (!form.tipo) 
      errors.tipo = "El tipo es obligatorio.";
    if (!form.nombre_dueño) 
      errors.nombre_dueño = "El nombre del dueño es obligatorio.";
    if (!form.ubicacion_comentario) 
      errors.ubicacion_comentario = "La ubicación o comentario es obligatorio.";
    if (!form.solicitada_por) 
      errors.solicitada_por = "El campo 'Solicitada por' es obligatorio.";
    if (!form.fecha_termino_contrato) 
      errors.fecha_termino_contrato = "La fecha de término de contrato es obligatoria.";
    if (!form.fecha_entrega_numero) 
      errors.fecha_entrega_numero = "La fecha de entrega del número es obligatoria.";
    if (!form.observaciones) 
      errors.observaciones = "Las observaciones son obligatorias.";
    if (!form.autorizador) 
      errors.autorizador = "El autorizador es obligatorio.";
    if (!form.observaciones_otorgamiento) 
      errors.observaciones_otorgamiento = "Las observaciones de otorgamiento son obligatorias.";
  
    return errors;
  };

  const handleForm = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      console.error("Error handling form submission:", errors);

      setGeneralError("Por favor, complete todos los campos obligatorios.");
      return;
    }

    const url = addOrEdit ? `vehiculos/${form.numero_letrero_metalico}/` : "vehiculos/";
    const method = addOrEdit ? "patch" : "post";

    try {
      await axiosInstance[method](url, form);
      const paginatedData = await getPaginatedData();
      setPaginatedData(paginatedData);
      setShowCreateModal(false);
      resetForm();
      setErrorMessages({});
      setGeneralError("");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessages(error.response.data);
      } else {
        console.error("Error handling form submission:", error);
      }
    }
  };

  const handleCancelCreate = () => {
    resetForm();
  };

  const handleItemMenu = (id) => {
    setItemMenuOpen({
      idUser: itemMenuOpen.idUser === id ? null : id,
    });
  };


  const handleEditVehicle = (vehiculo) => {
    setAddOrEdit(1);
    setShowCreateModal(true);
    setForm({
      numero_letrero_metalico: vehiculo.numero_letrero_metalico,
      patente: vehiculo.patente,
      tipo: vehiculo.tipo,
      nombre_dueño: vehiculo.nombre_dueño,
      ubicacion_comentario: vehiculo.ubicacion_comentario,
      solicitada_por: vehiculo.solicitada_por,
      fecha_termino_contrato: vehiculo.fecha_termino_contrato,
      fecha_entrega_numero: vehiculo.fecha_entrega_numero,
      observaciones: vehiculo.observaciones,
      autorizador: vehiculo.autorizador,
      observaciones_otorgamiento: vehiculo.observaciones_otorgamiento,
    });
  };

  const handleDeleteVehicle = (vehiculo) => {
    setShowDeleteModal(true);
    setForm({
      numero_letrero_metalico: vehiculo.numero_letrero_metalico,
      patente: vehiculo.patente,
      tipo: vehiculo.tipo,
      nombre_dueño: vehiculo.nombre_dueño,
      ubicacion_comentario: vehiculo.ubicacion_comentario,
      solicitada_por: vehiculo.solicitada_por,
      fecha_termino_contrato: vehiculo.fecha_termino_contrato,
      fecha_entrega_numero: vehiculo.fecha_entrega_numero,
      observaciones: vehiculo.observaciones,
      autorizador: vehiculo.autorizador,
      observaciones_otorgamiento: vehiculo.observaciones_otorgamiento,
    });
  };

  const handleConfirmDelete = async () => {
    const { numero_letrero_metalico } = form;
    try {
      await axiosInstance.delete(`vehiculos/${numero_letrero_metalico}/`);
      const userList = await getPaginatedData();
      setPaginatedData(userList);
      setShowDeleteModal(false);
      resetForm();
    } catch (error) {
      console.error("Error deleting Vehicle:", error);
    }
  };

  const resetForm = () => {
    setForm(INITIAL_FORM);
    setErrorMessages({});
    setGeneralError("");
  };

  const handleSelectId = (numero_letrero_metalico) => {
    setSelectedIds((prev) => {
      if (prev.includes(numero_letrero_metalico)) {
        return prev.filter((selectedId) => selectedId !== numero_letrero_metalico);
      } else {
        return [...prev, numero_letrero_metalico];
      }
    });
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedIds([]);
    } else {
      const allIds = paginatedData.results.map(
        (vehiculo) => vehiculo.numero_letrero_metalico,
      );
      setSelectedIds(allIds);
    }
    setAllSelected(!allSelected);
  };

  const processEntry = (entry) => {
    if (!entry || entry === "nan") {
      return "-";
    } else {
      return entry;
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return format(new Date(dateString), "dd/MM/yyyy");
  };


  return (
    <div
      className={`${main.mainContent} ${isSidebarCollapsed ? main.expanded : main.collapsed}`}
    >
      <Header current={6} onSidebarToggle={handleSidebarToggle} />

      <Modal
        show={showUploadModal}
        setter={setShowUploadModal}
        name="Subir archivo Excel"
        buttonName="Subir"
        onClickCancel={() => setShowUploadModal(false)}
        onClick={handleUploadFile}
      >
        <div className="container-fluid">
          <div className="form-group">
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div className="form-group">
            <p>
              Puedes descargar la plantilla del archivo{" "}
              <a href="/TranquerasVehiculosPlantilla.xlsx" download>
                aquí
              </a>
              .
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        show={showCreateModal}
        setter={setShowCreateModal}
        name={`${addOrEdit ? "Editar" : "Agregar"} Vehiculo`}
        buttonName={addOrEdit ? "Guardar" : "Agregar"}
        onClickCancel={handleCancelCreate}
        onClick={handleForm}
      >
        <div className="container-fluid">
          {generalError && (
            <div className="alert alert-danger">{generalError}</div>
          )}
          <div className="form-group">
            <TextInput
              name={"N° Letrero Metálico"}
              form={form}
              setForm={setForm}
              internalName={"numero_letrero_metalico"}
              errorMessage={errorMessages.numero_letrero_metalico}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Patente"}
              form={form}
              setForm={setForm}
              internalName={"patente"}
              errorMessage={errorMessages.patente}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Tipo"}
              form={form}
              setForm={setForm}
              internalName={"tipo"}
              errorMessage={errorMessages.tipo}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Nombre Dueño"}
              form={form}
              setForm={setForm}
              internalName={"nombre_dueño"}
              errorMessage={errorMessages.nombre_dueño}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Ubicación Comentario"}
              form={form}
              setForm={setForm}
              internalName={"ubicacion_comentario"}
              errorMessage={errorMessages.ubicacion_comentario}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Solicitada Por"}
              form={form}
              setForm={setForm}
              internalName={"solicitada_por"}
              errorMessage={errorMessages.solicitada_por}
            />
          </div>
          <div className="form-group">
            <DatePickerInput
              name={"Fecha Término Contrato"}
              form={form}
              setForm={setForm}
              internalName={"fecha_termino_contrato"}
              errorMessage={errorMessages.fecha_termino_contrato}
            />
          </div>
          <div className="form-group">
            <DatePickerInput
              name={"Fecha Entrega Número"}
              form={form}
              setForm={setForm}
              internalName={"fecha_entrega_numero"}
              errorMessage={errorMessages.fecha_entrega_numero}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Observaciones"}
              form={form}
              setForm={setForm}
              internalName={"observaciones"}
              errorMessage={errorMessages.observaciones}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Autorizador"}
              form={form}
              setForm={setForm}
              internalName={"autorizador"}
              errorMessage={errorMessages.autorizador}
            />
          </div>
          <div className="form-group">
            <TextInput
              name={"Observaciones Otorgamiento"}
              form={form}
              setForm={setForm}
              internalName={"observaciones_otorgamiento"}
              errorMessage={errorMessages.observaciones_otorgamiento}
            />
          </div>

        </div>
      </Modal>

      <Modal
        show={showDeleteModal}
        setter={setShowDeleteModal}
        name={"Eliminar Vehiculo"}
        buttonName={"Eliminar"}
        onClickCancel={handleCancelCreate}
        onClick={handleConfirmDelete}
      >
        <div>
          <div className={"d-flex flex-row align-items-center w-100"}>
            {`¿Está seguro que desea eliminar al usuario ${form.nombre} ${form.apellido}?`}
          </div>
        </div>
      </Modal>

      <div className={"bg-light vh-100 mt-3"}>
        <div className={`${styles.createContainer}`}>
          <div
            className={"d-flex align-items-center"}
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <div className={`${styles.listTitle}`}>Lista de Vehículos</div>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <button
                  className={`btn ${main.mainButton}`}
                  onClick={() => {
                    setShowCreateModal(true);
                    setAddOrEdit(0);
                  }}
                >
                  Agregar
                </button>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className={`btn ${main.mainButton}`}
                  onClick={handleDownloadFile}
                >
                  Descargar
                </button>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className={`btn ${main.mainButton}`}
                  onClick={() => setShowUploadModal(true)}
                >
                  Subir
                </button>
              </div>
              <div className="d-flex align-items-center">
                <input
                  className="inputBorderDefault form-control rounded-2"
                  placeholder="Buscar"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={vehicleStyles.tableContainer}>
          <table className={`${styles.listTable} mt-3`}>
            <thead>
              <tr>
                <th className={styles.tableItemHeader}>
                  <input
                    type="checkbox"
                    checked={allSelected}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className={`${styles.tableItemHeader}`}>Numero Mina</th>
                <th className={`${styles.tableItemHeader}`}>Matrícula</th>
                <th className={`${styles.tableItemHeader}`}>Tipo</th>
                <th className={`${styles.tableItemHeader}`}>Nombre Dueño</th>
                <th className={`${styles.tableItemHeader}`}>Ubicación Comentario</th>
                <th className={`${styles.tableItemHeader}`}>Solicitada Por</th>
                <th className={`${styles.tableItemHeader}`}>Fecha Término Contrato</th>
                <th className={`${styles.tableItemHeader}`}>Fecha Entrega Número</th>
                <th className={`${styles.tableItemHeader}`}>Observaciones</th>
                <th className={`${styles.tableItemHeader}`}>Autorizador</th>
                <th className={`${styles.tableItemHeader}`}>Observaciones Otorgamiento</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {paginatedData &&
                paginatedData.results.map((vehiculo) => (
                  <tr key={vehiculo.numero_letrero_metalico}>
                    <td className={styles.tableItem}>
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(vehiculo.numero_letrero_metalico)}
                        onChange={() => handleSelectId(vehiculo.numero_letrero_metalico)}
                      />
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(vehiculo.numero_letrero_metalico)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(vehiculo.patente)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(vehiculo.tipo)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(vehiculo.nombre_dueño)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(vehiculo.ubicacion_comentario)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(vehiculo.solicitada_por)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {formatDate(vehiculo.fecha_entrega_numero)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {formatDate(vehiculo.fecha_termino_contrato)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(vehiculo.observaciones)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(vehiculo.autorizador)}
                    </td>
                    <td className={`${styles.tableItem}`}>
                      {processEntry(vehiculo.observaciones_otorgamiento)}
                    </td>
                    <td>
                      <MenuButton
                        onClick={() => handleItemMenu(vehiculo.numero_letrero_metalico)}
                      >
                        {itemMenuOpen.idUser === vehiculo.numero_letrero_metalico && (
                          <div className={`${userStyles.dropdown}`}>
                            <div
                              onClick={() => handleEditVehicle(vehiculo)}
                              className={`${userStyles.dropdownItem}`}
                            >
                              Editar
                            </div>
                            <div
                              onClick={() => handleDeleteVehicle(vehiculo)}
                              className={`${userStyles.dropdownItem}`}
                            >
                              Eliminar
                            </div>
                          </div>
                        )}
                      </MenuButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <MyPaginate
          className="mt-3"
          breakLabel="..."
          nextLabel="Siguiente"
          onPageChange={(page) => setCurrentPage(page.selected + 1)}
          pageRangeDisplayed={3}
          pageCount={totalPages}
          previousLabel="Anterior"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}
