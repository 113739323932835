// sidebar.js
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./styles/sidebar.module.css";
import { selectUser } from "../features/auth/authSelectors";
import { ROLES } from "../constants/roles";

export default function Sidebar({
  current,
  subCurrent,
  isSidebarCollapsed,
  onToggle,
}) {
  const [isExpanded, setIsExpanded] = useState(!isSidebarCollapsed);
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  useEffect(() => {
    setIsExpanded(!isSidebarCollapsed);
  }, [isSidebarCollapsed]);

  const goto = (url) => {
    navigate(url);
  };

  const [items, setItems] = useState(() => {
    const allItems = [
      {
        name: "Sistema de Barreras",
        icon: "/sidebar/tranquera.png",
        expandActive: false,
        // children: [
        //     { name: 'Lista', onClick: () => goto('/gates/list') },
        //     { name: 'Programación', onClick: () => goto('/gate_programming/list') },
        // ],
        onClick: () => goto("/gates/list"),
      },
      {
        name: "Usuarios",
        icon: "/sidebar/people.png",
        onClick: () => goto("/people/list"),
      },
      {
        name: "Historial",
        icon: "/sidebar/history.png",
        onClick: () => goto("/history/list"),
      },
      {
        name: "Eventos",
        icon: "/sidebar/bell.png",
        onClick: () => goto("/events/list"),
      },
      {
        name: "Galería",
        icon: "/sidebar/gallery.png",
        onClick: () => goto("/gallery/list"),
      },
      {
        name: "Personas",
        icon: "/sidebar/people.png",
        onClick: () => goto("/persons/list"),
      },
      {
        name: "Vehículos",
        icon: "/sidebar/car.png",
        onClick: () => goto("/vehicle/list"),
      },
    ];

    if (user.is_superuser) {
      return allItems;
    } else {
      return user.zona.nombre_zona === ROLES.UJINA
        ? allItems.filter(
          (item) => item.name !== "Personas" && item.name !== "Vehículos",
        )
        : allItems;
    }


  });

  useEffect(() => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      if (
        current !== null &&
        current !== undefined &&
        subCurrent !== null &&
        subCurrent !== undefined
      ) {
        updatedItems[current].expandActive = true;
      }
      return updatedItems;
    });
  }, [current, subCurrent]);

  const toggleExpand = (index) => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].expandActive = !updatedItems[index].expandActive;
      return updatedItems;
    });
  };

  const toggleSidebar = () => {
    const newIsExpanded = !isExpanded;
    setIsExpanded(newIsExpanded);
    if (onToggle) {
      onToggle(!isSidebarCollapsed);
    }
  };

  return (
    <div
      className={`${styles.sidebar} ${isExpanded ? styles.expanded : styles.collapsed}`}
    >
      <button className={styles.toggleSidebar} onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isExpanded ? faChevronLeft : faChevronRight} />
      </button>
      <div className={`${styles.sections} mt-5 pt-5`}>
        {items.map((item, index) => (
          <div
            key={item.name}
            className={`align-items-start ${styles.row} ${isExpanded && styles.expandedRow} ${index === current && styles.selected}`}
            style={isExpanded ? { flexDirection: "column" } : {}}
            role={"button"}
            onClick={(item.children ?? []).length === 0 ? item.onClick : null}
            aria-expanded={item.expandActive}
          >
            <div
              className={"d-flex align-items-center "}
              style={{ justifyContent: "center" }}
              onClick={
                (item.children ?? []).length === 0
                  ? null
                  : () => toggleExpand(index)
              }
            >
              <img alt={item.name} src={item.icon} width={"32px"} />
              <div className={`${styles.rowText} ps-3`}>{item.name}</div>
              {(item.children ?? []).length > 0 && (
                <div className={`py-1 px-2`}>
                  {item.expandActive ? (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      style={{ color: "white" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={{ color: "white" }}
                    />
                  )}
                </div>
              )}
            </div>
            <div className={`${styles.subContainer}`}>
              {item.children &&
                item.expandActive &&
                item.children.map((child, childIndex) => (
                  <div
                    key={child.name}
                    style={{ padding: "10px 24px" }}
                    className={`${styles.row} ${childIndex === subCurrent && styles.subRowSelected}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      child.onClick();
                    }}
                    role="button"
                  >
                    <div className={`${styles.rowText}`}>{child.name}</div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
