import dayjs from "dayjs";
import 'dayjs/locale/es';
import React, { useEffect, useState } from "react";
import axiosInstance from "../../AxiosInstance";
import main from "../../styles/main.module.css";
import Header from "../../components/header";
import { TextInput } from "../../components/input/inputs";
import chooser from '../entities/chooser/type_chooser.module.css';



export default function GalleryList() {
    const [form, setForm] = useState({});
    const [type, setType] = useState('fotos');

    const [gates, setGates] = useState([]);
    const [currentGate, setCurrentGate] = useState({});
    const [filteredGates, setFilteredGates] = useState([]);

    const [photos, setPhotos] = useState([]);
    const [currentPhoto, setCurrentPhoto] = useState({});
    const [filteredPhotos, setFilteredPhotos] = useState([]);

    const [videos, setVideos] = useState([]);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
        const savedSidebarState = localStorage.getItem('isSidebarCollapsed');
        return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
    });


    const handleSidebarToggle = (isCollapsed) => {
        setIsSidebarCollapsed(isCollapsed);
        console.log(isCollapsed);
    };


    useEffect(() => {
        axiosInstance.get('gatesystem/').then(
            (response) => {
                // console.log(response.data)
                setGates(response.data.results)
                setFilteredGates(response.data.results)

            }
        )
    }, [])

    useEffect(() => {
        axiosInstance.get('galeria/fotos/').then((response) => {
            setPhotos(response.data);
            setFilteredPhotos(response.data);
            setCurrentPhoto(response.data.length > 0 ? response.data[0] : {})
        });
    }, []);

    useEffect(() => {
        axiosInstance.get('galeria/videos/').then((response) => {
            setVideos(response.data);
            // setCurrentVideo(response.data.length > 0 ? response.data[0] : {})

            // console.log(response.data)
        });
    }, []);

    useEffect(() => {
        if (!form.search) {
            setFilteredGates(gates)
        } else {

            setFilteredGates(gates.filter(gate => gate.nombre.toLowerCase().includes(form.search.toLowerCase())))
        }
    }, [form])

    useEffect(() => {
        if (!currentGate) {
            setFilteredPhotos(photos)

        } else {
            setFilteredPhotos(photos?.filter(photo => photo.gatesystem == currentGate.id) ?? [])
        }
    }, [currentGate])
    return (
        <div>
            <Header current={4} onSidebarToggle={handleSidebarToggle}/>

            <div className={`${main.mainContent} ${isSidebarCollapsed ? main.expanded : main.collapsed}`}>
                {/* <div className="d-flex" style={{ justifyContent: "end" }}>
                    <div style={{ width: "350px" }}>
                        <SelectInput form={[]} setForm={() => { }} options={[]} name={'Tipo de Vehículo'} />
                    </div>
                    <div style={{ width: "350px", marginLeft: "20px" }}>
                        <SelectInput form={[]} setForm={() => { }} options={[]} name={'Lista de Barreras'} />
                    </div>
                </div> */}
                <div className={'d-flex mt-3'} style={{ height: '100%', gap: '40px', alignItems: 'stretch' }}>
                    {/* <div>
                        <video src="https://media.w3.org/2010/05/sintel/trailer.mp4"></video>
                        <ReactPlayer loop={true}
                            url='https://media.w3.org/2010/05/sintel/trailer.mp4'
                            playing={true}
                            controls={true}

                            width='400px'
                            height='200px'
                        />
                    </div> */}
                    <div className={'px-1 py-3 sidebar-filter'} >
                        <div className={'mx-2'}>
                            <TextInput hideName={true} label={'Nombre'} internalName={'search'} placeholder={'Buscar'} form={form} setForm={setForm} />
                        </div>
                        <div className={'mx-2 px-1'} style={{
                            textAlign: 'left',
                            color: '#323659',
                            fontWeight: '700'
                        }}>Lista de {type === 'videos' ? 'cámaras' : 'sistemas de Barreras'}</div>
                        <div style={{
                            overflowY: 'scroll',
                            userSelect: 'none',
                            maxHeight: 'calc(100vh - 300px)',
                        }}>
                            {type === 'fotos' && filteredGates.map((gate, index) => {
                                return (
                                    <div className={'mx-2 my-2 py-1 px-1'} style={{
                                        textAlign: 'center',
                                        backgroundColor: currentGate.id === gate.id ? '#36496D' : '#F6F7FC',
                                        color: currentGate.id === gate.id ? '#F6F7FC' : '#36496D',
                                        fontWeight: '600',
                                        borderRadius: '6px',
                                        fontSize: 'large',
                                        cursor: 'pointer',

                                    }}
                                        onClick={() => {
                                            setCurrentGate(gate);
                                        }}
                                    >
                                        {gate.nombre}
                                    </div>
                                )
                            }
                            )}
                            {
                                type === 'videos' && videos.map((video, index) => {
                                    return (
                                        <div className={'mx-2 my-2 py-1 px-1'} style={{
                                            textAlign: 'center',
                                            backgroundColor: currentVideo?.nombre === video.nombre ? '#36496D' : '#F6F7FC',
                                            color: currentVideo?.nombre === video.nombre ? '#F6F7FC' : '#36496D',
                                            fontWeight: '600',
                                            borderRadius: '6px',
                                            fontSize: 'large',
                                            cursor: 'pointer',
                                        }}
                                            onClick={() => {
                                                setCurrentVideo(video);
                                            }}
                                        >
                                            {video.nombre}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {/* <div style={{ width: '40px' }} /> */}
                    <div className={'py-3 px-3'} style={{
                        backgroundColor: '#fff',
                        width: '80%',
                        height: '850px',
                        boxShadow: '0 0 4px #ccc',
                        borderRadius: '6px'
                    }}>
                        <div>
                            <div className={'d-flex justify-content-between'} style={{ userSelect: 'none' }}>
                                <div className={'d-flex'}>
                                    <div onClick={() => setType('fotos')} className={type === 'fotos' ? chooser.selected : chooser.unselected}>Fotos</div>
                                    {/* <div onClick={() => setType('videos')} className={type === 'videos' ? chooser.selected : chooser.unselected}>Videos</div> */}
                                </div>
                            </div>
                        </div>

                        {type === 'videos' &&

                            (currentVideo ? <video width="100%" height='auto' controls className="mt-3" style={{
                                borderRadius: '6px',
                                maxHeight: 'calc(100% - 56px)',
                            }}>

                                <source src={currentVideo.url} type="video/mp4" />

                                Your browser does not support the video tag.

                            </video> : 'Seleccione una Cámara')
                        }
                        {type === 'fotos' && <div className={'d-flex mt-3 px-4 py-4'} style={{
                            border: '#CFD5F1 1px solid',
                            borderRadius: '6px',
                            height: 'calc(100% - 56px)',
                            width: '100%',
                        }}>
                            <div style={{
                                overflowY: 'scroll',
                                overflowX: 'hidden',
                                userSelect: 'none',
                                maxWidth: '150px',
                                width: '30%',
                            }}>
                                {filteredPhotos.map((photo, index) => (
                                    <div key={index} className={'me-2 my-2'} style={{ borderRadius: '6px' }} onClick={() => {
                                        // console.log(photo)
                                        setCurrentPhoto(photo);
                                    }}>
                                        <img alt={'Sin imagen'} src={`${photo.photo}`} style={{
                                            width: '100%', height: '80px', objectFit: 'cover', borderRadius: '6px',
                                            cursor: 'pointer',
                                        }} />
                                    </div>
                                ))}
                            </div>
                            <div style={{ overflow: 'auto', width: '100%', height: '100%' }}>
                                <div className={''} style={{
                                    width: '100%',
                                    height: '100%',
                                    minHeight: '400px',
                                }}>
                                    <div className={'mb-2 '} style={{
                                        alignItems: 'center',
                                        minHeight: '80%',
                                        // width: '100%'
                                        // height: 'calc(100% - 90px)',
                                        // maxHeight: 'calc(100% - 90px)',
                                    }} >
                                        {currentPhoto?.photo ?
                                            <img alt={'Placa'} src={`${currentPhoto?.photo}`} style={{ width: '100%', objectFit: 'contain', borderRadius: '6px' }} />
                                            :
                                            <div style={{ width: '100%', height: '50%' }}> Seleccione una imagen</div>
                                        }
                                    </div>
                                    <div className={'px-4 py-2'} style={{
                                        border: '#CFD5F1 1px solid',
                                        borderRadius: '6px',
                                        minHeight: '15%',
                                    }}>

                                        <div className={'d-flex justify-content-between'}>
                                            <div style={{
                                                color: '#36496D',
                                                fontWeight: '600'
                                            }}>{currentPhoto.datetime && dayjs(currentPhoto.datetime).locale('es').format("dddd MMMM DD, YYYY")} </div>
                                            <div style={{
                                                color: '#A0B1DB',
                                                fontWeight: '600'
                                            }}>{currentPhoto.datetime && dayjs(currentPhoto.datetime).format("HH:mm A")}</div>
                                        </div>

                                        <div className={'d-flex justify-content-between'}>
                                            <div style={{
                                                color: '#36496D',
                                                fontWeight: '600'
                                            }}>Barrera: </div>
                                            <div style={{
                                                color: '#A0B1DB',
                                                fontWeight: '600'
                                            }}>{gates.find(gate => gate.id == currentPhoto?.gatesystem)?.nombre}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
        </div>
    );
}
