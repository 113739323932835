import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// import { useAuth } from "../context/AuthContext";
import { performLogout } from "../features/auth/authActions";
import Sidebar from "./sidebar";
import styles from "./styles/header.module.css";

export default function Header({ current, subCurrent, onSidebarToggle }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    const savedSidebarState = localStorage.getItem("isSidebarCollapsed");
    return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    onSidebarToggle(isSidebarCollapsed);
  }, [isSidebarCollapsed, onSidebarToggle]);

  const handleSidebarToggle = () => {
    const newSidebarState = !isSidebarCollapsed;
    setIsSidebarCollapsed(newSidebarState);
    localStorage.setItem("isSidebarCollapsed", JSON.stringify(newSidebarState));
    onSidebarToggle(newSidebarState);
  };

  const handleLogout = () => {
    dispatch(performLogout);
    navigate("login/");
  };

  return (
    <div>
      <nav className={`navbar-light ${styles.header}`}>
        <div
          className={
            "d-flex justify-content-between ms-5 me-3 align-items-center"
          }
        >
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            src={"/logo_stracontech_original.png"}
            alt="Company Logo"
            height={"55px"}
          />
          <div className={`${styles.userMenu}`}>
            <div
              className={"d-flex align-items-center"}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <div className={`${styles.userIcon} me-2`}>
                <img
                  src={"/user.png"}
                  alt={"user"}
                  height={"54px"}
                  width={"54px"}
                />
              </div>
              <div className={`${styles.chevronIcon} rounded-5 py-1 px-2`}>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="1x"
                  color="#162949"
                />
              </div>
              {menuOpen && (
                <div className={`${styles.dropdown}`}>
                  <Link to="/people/list" className={`${styles.dropdownItem}`}>
                    Usuarios
                  </Link>
                  <div
                    onClick={handleLogout}
                    className={`${styles.dropdownItem}`}
                  >
                    Salir
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      <Sidebar
        current={current}
        // subCurrent={subCurrent}
        isSidebarCollapsed={isSidebarCollapsed}
        onToggle={handleSidebarToggle}
      />
    </div>
  );
}
